import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  OnChanges,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  Injectable,
} from '@angular/core';
import OlMap from 'ol/Map';
import { ScaleLine, defaults as defaultOlControls } from 'ol/control';
import OlXYZ from 'ol/source/XYZ';
import OlView from 'ol/View';
import { fromLonLat, transformExtent } from 'ol/proj';

import {
  Vector as VectorLayer,
  Image as ImageLayer,
  VectorTile as VectorTileLayer,
  Tile as TileLayer,
  VectorImage as VectorImageLayer,
} from 'ol/layer';
// import ImageWMS from 'ol/source/ImageWMS';
import { OSM, Vector as VectorSource, TileArcGISRest } from 'ol/source';
import { apply } from 'ol-mapbox-style';
//google map
import GoogleLayer from 'olgm/layer/Google.js';
import OLGoogleMaps from 'olgm/OLGoogleMaps.js';
//end google map

import { transform } from 'ol/proj.js';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import OlFeature from 'ol/Feature';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style.js';
import LineString from 'ol/geom/LineString.js';
import { MapService } from '../../shared/services/map.service';

import { BusDetailsComponent } from './bus-details/bus-details.component';
import { RouteDetailsComponent } from './route-details/route-details.component';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent,
} from 'perfect-scrollbar-angular';
import { TripCompletionCalcService } from '../../shared/services/trip-completion-calc.service';
import { ActivatedRoute } from '@angular/router';

// import { WebSocketSubject } from 'rxjs/observable/dom/WebSocketSubject';
import { MqttService } from '../../shared/services/mqtt.service';
import {
  mqttData,
  mqttSendData,
  predefinedMessageData,
  offRouteData,
  LogsData,
} from '../../shared/others/data-types';
import {
  MqttType,
  ColorData,
  MapType,
  OverlayLayer,
} from '../../shared/others/constant';
import { HomeService } from '../../shared/services/home.service';
import { VoipService } from '../../shared/services/voip.service';
import moment from 'moment';
import ContextMenu from 'ol-contextmenu';
// import { trigger, transition, style, animate, state } from '@angular/animations';
import { CommonService } from '../../shared/services/common.service';
import { fadeInOut } from '../../shared/others/animation/fadeInOut';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../shared/services/auth.service';
// import { WebSocketSubject } from 'rxjs/internal-compatibility';
import { WebSocketSubject } from 'rxjs/webSocket';
import { MenuService } from '../../shared/services/menu.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Coordinate } from 'ol/coordinate';
import { quickFade } from '../../shared/others/animation/quickFade';
import { boundingExtent } from 'ol/extent';
import { retry } from 'rxjs/operators';

const baseUrl = 'https://basemaps-api.arcgis.com/arcgis/rest/services/styles';
const getBaseMapUrl = (name, apiKey) =>
  `${baseUrl}/${name}?type=style&token=${apiKey}`;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [fadeInOut, quickFade],
})
export class HomeComponent
  implements OnChanges, OnInit, OnDestroy, AfterViewInit
{
  map: OlMap;
  source: OlXYZ;
  OSMLayer: TileLayer<any>;
  currentLayer: TileLayer<any> | VectorTileLayer | undefined;
  view: OlView;
  // draw; // global so we can remove it later
  // routeLayer: VectorLayer = [];
  orientation: string = 'bottom';
  ladders: any = {};
  busSwitchListData: any = {};
  // switchData = []; //on and off
  // currComponentRef; //init here to be destroy later

  componentsReferences = []; // to store references of dynamically created components
  index: number = 0;

  busDetailsList = [];
  isNewWindow: boolean = false;

  @ViewChild('busDetailsWrapper', { read: ViewContainerRef })
  VCR: ViewContainerRef;

  public config: PerfectScrollbarConfigInterface = {};
  pinCount: number = 0;
  provider = 'OpenStreetMap'; //this.mapService.mapProvider;

  busMqtt: any = {};
  // busMqttClearInterval: any;
  isBirectional: boolean;

  google: any;

  currGoogleLayer: string = '';
  glRoadmap: any;
  glTerrain: any;
  glSatellite: any;
  glHybrid: any;
  // isMapType: boolean;

  // isTraffic: boolean;
  // isTrafficLayer: boolean;
  olGM;
  trafficLayer;
  busStopHiglighted = [];
  environment = environment;
  selectedBasemap: string = 'OSM';

  boundingExtent = boundingExtent([
    fromLonLat(environment.map.boundingExtent.min),
    fromLonLat(environment.map.boundingExtent.max),
  ]);

  viewAnimate: OlView = new OlView({
    center: fromLonLat(environment.map.locationLonLat),
    zoom: environment.map.defaultZoom,
    minZoom: environment.map.minZoom,
    maxZoom: environment.map.maxZoom,
    projection: 'EPSG:900913',
    extent: this.boundingExtent,
  });

  //subscription
  private socket$: WebSocketSubject<any>;
  // socketPredefined$: WebSocketSubject<any>;
  private socketOffRoute$: WebSocketSubject<any>;

  mapMarkerBlink: any = [];
  blinkColors = ['map-marker-blue.png', 'map-marker-green.png'];
  setOffRouteData: offRouteData;

  // setPredefinedData = [];
  prefeinedMessages = [];
  prefeinedMessagesList = [];
  // mqttMessageText;
  openedRouteList = [];
  openedRoute = [];

  preventSingleClick = false;
  preventSingleClickTimer: any;
  @ViewChild('stopDetailsAll') stopDetailsAll: PerfectScrollbarComponent;

  bbPercentage: number = 0;
  bbWidth: number = 0;
  busExplorerData = [];
  isBusExplorer: boolean = false;
  // isMapOptions:boolean = false;
  mapIncidentData: any = {};
  mapOptions: any = {
    isIncident: false,
    isLadder: true,
    isMapLayer: false,
  };
  intervalMapIncident: any;
  // busMqtt2 = [
  //   // {
  //   //   "value": "1",
  //   //   "prevStopId": "28701",
  //   //   "nextStopId": "28101",
  //   //   "drivenPercentage": 80,
  //   //   "status": "-1",
  //   //   "isSelected": false,
  //   //   "busCode": "SBS1234"
  //   // },
  //   {
  //     "value": "1",
  //     // "stopCode":
  //     "prevStopId": "28009", //opp block 19, number 16
  //     // "nextStopId": "28699", //opp uni tech, number 29
  //     "nextStopId": "", //goh goh building, number 18
  //     "secondnextstopId": "",
  //     "drivenPercentage": 30,
  //     "status": "0",
  //     "isSelected": false,
  //     "busCode": "SBS5678"
  //   }
  // ];
  pageData = { id: 3, name: 'home', displayPageName: 'Home' };
  isHasAccess: boolean = true;
  rightsError = '';
  busDetailsData = [];

  constructor(
    private mapService: MapService,
    private CFR: ComponentFactoryResolver,
    private tripCompletionCalcService: TripCompletionCalcService,
    private route: ActivatedRoute,
    private mqttService: MqttService, // bus position service
    public homeService: HomeService,
    // private voipService: VoipService,
    private commonService: CommonService,
    private _snackBar: MatSnackBar,
    private menuService: MenuService,
    public authService: AuthService,
    private _spinner: NgxSpinnerService
  ) {
    this.commonService.onIdle();
  }

  ngOnChanges() {
    console.log('on change');
  }

  listenServiceRouteMqttNode(data: any) {
    if (!data.isChecked) {
      return;
    }

    this.socket$ = new WebSocketSubject(environment.nodeUrlWs); //nodeUrlLocalWs nodeUrlWs
    const me = this;
    let arrBus: any = [];
    let arrBusBunching = [];

    let tripNo: string,
      regNo: string,
      lat: string,
      lon: string,
      drivenpercentage: string,
      status: string,
      direction: string,
      nextStopId: string = '',
      degrees: string = '',
      busBunching: boolean = false,
      nextstopeta: string,
      secondnextstop: string,
      secondnextstopeta: string,
      thirdnextstop: string,
      thirdnextstopeta: string,
      driverId: string,
      blockId: string,
      previousStopId: string;

    // this.socket$.subscribe(
    this.socket$.pipe(retry()).subscribe(
      returnData => {
        let retStr = returnData.data
          .toString()
          .replace('[', '')
          .replace(']', '')
          .split(',');
        for (const tripRetData of retStr) {
          let tripData = tripRetData.split(':');
          let indexName = tripData[0].trim();
          if (indexName === 'trip') {
            tripNo = tripData[1];
          } else if (indexName === 'regno') {
            regNo = tripData[1];
          } else if (indexName === 'latitude') {
            lat = tripData[1];
          } else if (indexName === 'longitude') {
            lon = tripData[1];
          } else if (indexName === 'direction') {
            direction = tripData[1];
            // direction = Math.floor((Math.random() * 2) + 1).toString();
          } else if (indexName === 'drivenpercentage') {
            drivenpercentage = tripData[1];
          } else if (indexName === 'status') {
            status = tripData[1];
          } else if (indexName === 'nextstop') {
            nextStopId = tripData[1];
          } else if (indexName === 'compass') {
            degrees = tripData[1];
          } else if (indexName === 'busBunching') {
            busBunching = tripData[1];
          } else if (indexName === 'previousstop') {
            previousStopId = tripData[1];
          } else if (indexName === 'nextstopeta') {
            nextstopeta =
              tripData[2] === undefined ? '' : tripData[1] + ':' + tripData[2];
          } else if (indexName === 'secondnextstop') {
            secondnextstop = tripData[1] === null ? '' : tripData[1];
          } else if (indexName === 'thirdnextstop') {
            thirdnextstop = tripData[1] === null ? '' : tripData[1];
          } else if (indexName === 'secondnextstopeta') {
            secondnextstopeta =
              tripData[2] === undefined ? '' : tripData[1] + ':' + tripData[2];
          } else if (indexName === 'thirdnextstopeta') {
            thirdnextstopeta =
              tripData[2] === undefined ? '' : tripData[1] + ':' + tripData[2];
          } else if (indexName === 'driverid') {
            driverId = tripData[1];
          } else if (indexName === 'blockid') {
            blockId = tripData[1];
          }
        }

        let drivenPercentageDirection2: any;
        if (direction === '2') {
          drivenPercentageDirection2 = 100 - +drivenpercentage;
          drivenpercentage = drivenPercentageDirection2;
        }

        //dont include buses with no name
        if (regNo === '' || regNo === undefined) {
          return false;
        }

        localStorage.setItem('currentLadderData', JSON.stringify(this.ladders));

        let ret: mqttData = {
          trip: tripNo,
          busCode: regNo,
          lat: parseFloat(lat),
          lon: parseFloat(lon),
          drivenPercentage: parseFloat(drivenpercentage),
          direction: direction,
          prevStopId: previousStopId,
          status: status,
          isSelected: false,
          degrees: degrees,
          isBusBunching: busBunching,
          driverId: driverId,
          blockId: blockId,
          nextStopId: nextStopId,
          nextstopeta: nextstopeta,

          secondnextstopId: secondnextstop,
          secondnextstopeta: secondnextstopeta,

          thirdnextstopId: thirdnextstop,
          thirdnextstopeta: thirdnextstopeta,
        };

        for (let bus of this.busDetailsList) {
          if (bus === ret.busCode) {
            ret.isSelected = true;
          }
        }

        let objBus: any;
        const busData = ret;
        // me.busMqtt = ret;

        //layers for the bus location
        // console.log('mqtt', ret, data, me.busMqtt);
        this.mqttService.mqttConnect(me.map, data, me.busMqtt, busData);

        if (me.busMqtt && me.ladders) {
          let busCode = ret.busCode;

          for (const row of arrBus) {
            if (row.busCode === busCode) {
              arrBus = arrBus.filter(item => item !== row);
              arrBusBunching = arrBusBunching.filter(item => item !== row);
            }
          }

          // TEST: override data for stop ladder marker
          // ret.drivenPercentage = Math.floor(Math.random() * (100 - 1 + 1) + 1);
          // ret.drivenPercentage = 99.99;
          // ret.nextStopId = '17009';
          // ret.prevStopId = '17009';
          // ret.direction = '1';
          // ret.status = '1';

          objBus = ret;
          objBus.ratio = ret.drivenPercentage;
          objBus.value = ret.direction;
          objBus.pin = '';
          objBus.expiryDate = moment().add(2, 'minutes');
          arrBus.push(objBus);

          //start bus bunching
          // if(ret.busCode === 'SBS5216' || ret.busCode === 'SBS8232' || ret.busCode === 'SBS4051') {
          //   ret.isBusBunching = true;
          // }

          // if(ret.isBusBunching) {
          //   arrBusBunching.push(objBus);
          //   let test = arrBusBunching.sort(function(obj1, obj2) {
          //     return obj1.ratio - obj2.ratio;
          //   });

          //   var firstItem = test[0];
          //   var lastItem = test[test.length-1];

          //   this.bbPercentage = firstItem.drivenPercentage;
          //   this.bbWidth = 100-(firstItem.drivenPercentage+(100-lastItem.drivenPercentage));
          // }
          //end bus bunching
          // console.log('arrBus: ', arrBus);
          this.busMqtt = arrBus;
          this.vehicleLadderPosition();
        }
      },
      err => console.error(err),
      () => console.warn('Completed!')
    );

    // this.busMqttClearInterval = setInterval(function() {
    //   let mqttLayer = 'VectorLayerMqtt-';
    //   let layers = me.map.getLayers().getArray().slice();
    //   for (let row of layers) {
    //     let rowName = row.get('name');
    //     if(rowName !== '' && rowName !== undefined && rowName.indexOf(mqttLayer) !== -1) {
    //       me.map.removeLayer(row);
    //     }
    //   }
    // },environment.bus.clearInterval);
  }

  public mqttRequest(data): void {
    const mqttSend: mqttSendData = {
      type: data.type,
      busRegNo: data.busRegNo === undefined ? '' : data.busRegNo,
      service: data.service === undefined ? '' : data.service,
    };
    const message = new mqttSendData(
      mqttSend.type,
      mqttSend.service,
      mqttSend.busRegNo
    );

    //const message = new Message(+data.service);
    if (MqttType.eVehiclePosition === mqttSend.type) {
      this.socket$.next(message);
    }
    // else if(MqttType.ePredefinedMessages === mqttSend.type) {
    //   this.socketPredefined$.next(message);
    // }
    else {
      this.socketOffRoute$.next(message);
    }
  }

  geoFencesLayer: VectorLayer<any>;
  initGeoFencesLayer() {
    const clementiVertices: Coordinate[][] = [
      [
        [103.7640884, 1.317145],
        [103.7619263, 1.3151238],
        [103.7633753, 1.3132201],
        [103.7647641, 1.3155723],
      ],
    ];
    const clementiFeature = new OlFeature({
      geometry: new Polygon(clementiVertices),
    });
    clementiFeature.getGeometry().transform('EPSG:4326', 'EPSG:3857');

    const geoFenceSource = new VectorSource({
      features: [clementiFeature],
    });

    this.geoFencesLayer = new VectorLayer({
      source: geoFenceSource,
    });

    this.geoFencesLayer.setVisible(false);

    this.map.addLayer(this.geoFencesLayer);
  }

  ngOnInit() {
    let me = this;
    // setTimeout(() => {
    //   console.log('after 5 sec');
    //   console.log(this.ladders.direction);
    //   me.busMqtt2 = [
    //     {
    //       "value": "1",
    //       "stopCode": "99029",
    //       "stopRatio": 80,
    //       "status": "-1",
    //       "isSelected": false,
    //       "busCode": "SBS1234"
    //     },
    //     {
    //       "value": "2",
    //       "stopCode": "01012",
    //       "stopRatio": 20,
    //       "status": "0",
    //       "isSelected": false,
    //       "busCode": "SBS4321"
    //     }
    //   ];
    // }, 5000);
    // let serviceNo = 'CFZ Loop';
    // let data = [{"ServiceNo":"CFZ Loop","Operator":"BCDA","NextBus":{"BusRegNo":"J367","OriginCode":"NA","DestinationCode":"NA","EstimatedArrival":"2019-12-06 12:06:07.492","Latitude":"0.0","Longitude":"0.0","VisitNumber":10,"Load":"0","Feature":"NA","Type":"NA"},"NextBus1":{"BusRegNo":"J366","OriginCode":"NA","DestinationCode":"NA","EstimatedArrival":"2019-12-06 12:20:08.492","Latitude":"0.0","Longitude":"0.0","VisitNumber":10,"Load":"0","Feature":"NA","Type":"NA"},"NextBus2":{"BusRegNo":"J370","OriginCode":"NA","DestinationCode":"NA","EstimatedArrival":"2019-12-06 12:36:51.492","Latitude":"0.0","Longitude":"0.0","VisitNumber":10,"Load":"0","Feature":"NA","Type":"NA"}}];
    //test bus details
    // setTimeout(() => {
    //   var data = {
    //     stopCode: 'stopCode',
    //     stopDesc: 'stopDesc',
    //     stopName: 'stopName',
    //     fromCode: 'fromCode',
    //     fromDesc: 'fromDesc',
    //     fromName: 'fromName',
    //     route: 'route',
    //     busCode: 'busRegNo',
    //     status: '0'
    //   }
    //   this.onBusDetails(data);
    // }, 10000);
    //end test bus details
    //temporary removed
    // this.initMapIncident();
    // var me = this;
    // this.intervalMapIncident = setInterval(function() {
    //   me.initMapIncident();
    //   if(me.mapIncidentData.RoadBlock.isActive) {
    //     me.onMapOption(me.mapIncidentData.RoadBlock);
    //   }
    //   if(me.mapIncidentData.Accident.isActive) {
    //     me.onMapOption(me.mapIncidentData.Accident);
    //   }
    //   if(me.mapIncidentData.Roadwork.isActive) {
    //     me.onMapOption(me.mapIncidentData.Roadwork);
    //   }
    //   if(me.mapIncidentData.VehicleBreakdown.isActive) {
    //     me.onMapOption(me.mapIncidentData.VehicleBreakdown);
    //   }
    //   if(me.busMqtt.length > 0){
    //     me.busMqtt.forEach(element => {
    //       if(moment().diff(element.expiryDate) < 0) {
    //         console.log('future');
    //       }
    //       else {
    //         let filteredData = me.busMqtt.filter(x => x.busCode !== element.busCode)
    //         me.busMqtt = filteredData;
    //         me.mqttService.removeBus(element);
    //       }
    //     });
    //   }
    // },30000);
    // //end temporary removed
    // var expiryDate = moment().add(2, 'minutes');
    // var expiryDate2 = moment().subtract(2, 'minutes');
    // if(moment().diff(expiryDate2) < 0) {
    //   console.log('future')
    // }
    // else {
    //   console.log('past')
    // }
    // console.log(moment().diff(expiryDate));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      //workaround if remove map doesn't load
      console.log('on init');
      // VOIP
      // this.voipService.initVoip();
      //end  VOIP

      this.initSwitch();

      if (this.route.snapshot.params.id === '2') {
        this.isNewWindow = true;
      }

      // this.source = new OSM({
      //   url: 'assets/maps/Tiles/{z}/{y}/{x}.png'
      //C:/Users/Casper Macatangay/Documents/projects/st-engr/fms-web-interface/src/
      // params: {
      //   'FORMAT': 'image/png',
      //   'VERSION': '1.1.1',
      //   'TILED': true,
      //   'LAYERS': 'geo-myTileLayer'
      // },
      // projection: 'EPSG:900913',
      // reprojectionErrorThreshold: 0.1
      // });

      // this.OSMLayer = new OlTileLayer({
      //   source: this.source
      // });

      // this.OSMLayer = new OlTileLayer({
      //   source: new OSM({
      //     url: 'assets/maps/Tiles/{z}/{x}/{y}.png'
      //   })
      // });

      // var image1 = new ImageWMS({
      //   url: 'http://localhost:8080/geoserver/wsSingapore/wms',
      //   params: {
      //     'LAYERS': 'wsSingapore:layerPoly'
      //   },
      //   'serverType': 'geoserver'
      // });

      // var layer1 = new ImageLayer({
      //   source: image1
      // });
      if (
        environment.project === 'clark' ||
        environment.project === 'cetrac' ||
        environment.project === 'qatar'
      ) {
        this.OSMLayer = new TileLayer({
          // source: new OSM()
          source: new OSM({
            url: `${environment.map.staticMap}/{z}/{x}/{y}.png`,
            crossOrigin: null,
          }),
        });
      } else {
        this.OSMLayer = new TileLayer({
          //environment.map.osmLayer // 'assets/maps/Tiles/{z}/{x}/{y}.png'
          source: new OSM({
            // url: environment.map.osmLayer
            url: `${environment.map.staticMap}/{z}/{x}/{y}.png`,
            crossOrigin: null,
          }),
        });
      }
      //testing layer
      // var image2 = new ImageWMS({
      //   url: 'http://localhost:8080/geoserver/wsSingapore/wms',
      //   params: {
      //     'LAYERS': 'wsSingapore:singapore_highway'
      //   },
      //   'serverType': 'geoserver'
      // });

      // var layer2 = new ImageLayer({
      //   source: image2
      // });

      // var image3 = new OlTileLayer({
      //   source: new OSM()
      // });

      // // var layer3 = new ImageLayer({
      // //   source: image3
      // // });

      // var image4 = new ImageWMS({
      //   url: 'http://localhost:8080/geoserver/wsSingapore/wms',
      //   params: {
      //     'LAYERS': 'wsSingapore:processed_p'
      //   },
      //   'serverType': 'geoserver'
      // });

      // var layer4 = new ImageLayer({
      //   source: image4
      // });

      // var image5 = new ImageWMS({
      //   url: 'http://localhost:8080/geoserver/wsSingapore/wms',
      //   params: {
      //     'LAYERS': 'wsSingapore:building_a'
      //   },
      //   'serverType': 'geoserver'
      // });

      // var layer5 = new ImageLayer({
      //   source: image5
      // });
      //end testing layer

      const scaleLine = new ScaleLine({
        units: 'metric',
      });

      this.map = new OlMap({
        controls: defaultOlControls().extend([scaleLine]),
        target: 'map',
        view: this.viewAnimate,
      });
      // this.map.addLayer(layer4);
      this.map.addLayer(this.OSMLayer);
      this.currentLayer = this.OSMLayer;
      // this.map.addLayer(image3);
      // this.map.addLayer(layer2);
      // this.map.addLayer(layer5);
      // this.map.addLayer(layer1);

      // init overlay layers
      this.initOverlayLayers();
      this.initGeoFencesLayer();

      let busStopPointerLayer = new VectorImageLayer({
        source: new VectorSource({
          features: [],
        }),
        className: 'busStopIndicatorLayer',
        properties: {
          name: 'busStopIndicatorLayer',
        },
      });

      busStopPointerLayer.setZIndex(3);
      busStopPointerLayer.setVisible(true);

      this.map.addLayer(busStopPointerLayer);
      // this.OSMLayer.setVisible(false);

      this.mapClick(null);

      this.initMqtt();

      this.mapMenu();

      setTimeout(() => {
        this.isHasAccess = this.authService.isHasAccess(this.pageData);
        this.rightsError = "You don't have access rights to this module.";
        //google layer top most
        // this.onChangeProvider('GoogleMap');
        // this.trafficToggle({'checked':true});
      }, 500);

      this.watchMenuChange();
    }, 0);
  }

  applyMapStyle(basemapId: MapType) {
    this.selectedBasemap = basemapId;
    if (basemapId === MapType.OSM) {
      if (this.currentLayer) {
        this.map.removeLayer(this.currentLayer);
      } else {
        let layers = this.map.getLayers().getArray().slice();
        const mapBoxLayers = layers.filter(layer => layer.get('mapbox-source'));
        let me = this;
        mapBoxLayers.forEach(layer => me.map.removeLayer(layer));
      }
      this.map.removeLayer(this.OSMLayer);
      this.map.getLayers().insertAt(0, this.OSMLayer);
      this.currentLayer = this.OSMLayer;
    } else if (
      basemapId === MapType.Navigation ||
      basemapId === MapType.Satellite
    ) {
      const apiKey = environment.esriApiKey;
      const baseMapUrl = getBaseMapUrl(basemapId, apiKey);
      let layers = this.map.getLayers().getArray().slice();

      if (this.currentLayer) {
        this.map.removeLayer(this.currentLayer);
        this.currentLayer = undefined;
      }
      const mapBoxLayers = layers.filter(layer => layer.get('mapbox-source'));
      let me = this;
      mapBoxLayers.forEach(layer => me.map.removeLayer(layer));

      apply(this.map, baseMapUrl);
    }
  }

  initOverlayLayers() {
    this.initMapTraffic();
  }

  listOverlayLayers: OverlayLayer[] = [];
  initMapTraffic() {
    const trafficServiceUrl =
      'https://traffic.arcgis.com/arcgis/rest/services/World/Traffic/MapServer/export';
    const apiKey = environment.esriApiKey;

    const trafficTileLayer = new TileLayer({
      source: new TileArcGISRest({
        url: trafficServiceUrl,
        params: {
          token: apiKey,
          DPI: 224,
          size: '384,384',
          layers: 'show:41',
        },
        projection: 'EPSG:3857',
      }),
      opacity: 0.6,
      className: 'traffic-layer',
    });

    trafficTileLayer.setZIndex(1);
    trafficTileLayer.setVisible(false);
    this.map.addLayer(trafficTileLayer);
    this.listOverlayLayers.push({
      name: 'Traffic',
      layer: trafficTileLayer,
      enabled: false,
      icon: 'traffic.png',
    });
  }

  toggleOverlayLayer(layer: { name: string; enabled: boolean; index: number }) {
    const currLayer = this.listOverlayLayers[layer.index];
    currLayer.enabled = layer.enabled;
    currLayer.layer.setVisible(layer.enabled);
  }

  mapMenu() {
    var contextmenuItems = [
      // {
      //   text: 'Bus Explorer',
      //   // classname: 'bold',
      //   icon: '<i class="material-icons">directions_bus</i>',
      //   callback: this.busExplorer,
      //   data: this,
      // },
      // {
      //   text: 'Some Actions',
      //   //icon: listIcon,
      //   items: [
      //     {
      //       text: 'Center map here',
      //       //icon: centerIcon,
      //       // callback: this.center
      //     },
      //     {
      //       text: 'Add a Marker',
      //       //icon: pinIcon,
      //       // callback: marker
      //     }
      //   ]
      // },
      // {
      //   text: 'Add a Marker',
      //   //icon: pinIcon,
      //   // callback: marker
      // },
      '-', // this is a separator
    ];

    var contextmenu = new ContextMenu({
      width: 180,
      items: contextmenuItems,
    });

    this.map.addControl(contextmenu);

    contextmenu.on('open', evt => {
      let lonLat = transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
      console.log(
        evt.coordinate,
        transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326')
      );
      fetch(
        'http://nominatim.openstreetmap.org/reverse?format=json&lon=' +
          lonLat[0] +
          '&lat=' +
          lonLat[1]
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          console.log(json);
        });
    });
  }

  busExplorer(me) {
    me.data.busExplorerData = [
      { name: 'Bus Route 1', lat: 123, lon: 456 },
      { name: 'Bus Route 2', lat: 123, lon: 456 },
      { name: 'Bus Route 3', lat: 123, lon: 456 },
    ];
    me.data.isBusExplorer = true;
  }

  closeBusExplorer() {
    this.isBusExplorer = false;
  }

  initMqtt() {
    // this.initPredefinedMessages();
    //this.initOffRoute();
  }

  // initOffRoute() {
  //   const mqttSend:mqttSendData = {
  //     type: MqttType.eOffRoute,
  //     busRegNo: '',
  //     service: ''
  //   }
  //   this.listenOffRoute();
  //   this.mqttRequest(mqttSend);
  // }

  // listenOffRoute() {
  //   this.socketOffRoute$ = new WebSocketSubject(this.environment.nodeUrlWs
  //     // configService.nodeUrlWs
  //   );

  //   this.socketOffRoute$.subscribe(
  //     (returnData) => {
  //       console.log(returnData);
  //     }
  //   )
  // }

  // testMessageInterval;
  // initPredefinedMessages() {
  //   const mqttSend:mqttSendData = {
  //     type: MqttType.ePredefinedMessages,
  //     busRegNo: '',
  //     service: ''
  //   }
  //   this.listenPredefinedMessages();
  //   this.mqttRequest(mqttSend);
  // }

  // listenPredefinedMessages() {
  //   this.socketPredefined$ = new WebSocketSubject(environment.nodeUrlWs); //nodeUrlLocalWs nodeUrlWs

  //   //test data
  //     // let message = {
  //     //   "busRegNo": "busRegNo",
  //     //   "tripId": "tripId",
  //     //   "routeId": "123",
  //     //   "latitude": "latitude",
  //     //   "longitude": "longitude",
  //     //   "messageType": "messageType",
  //     //   "messageNo": "messageNo",
  //     //   "messageText": "messageText",
  //     //   "messageTime": "messageTime" ,
  //     //   "isBlinkRedYellow": true,
  //     //   "isVisible": false
  //     // }
  //     // this.homeService.setPredefinedData.push(message);
  //     // this.prefeinedMessagesList.push('busRegNo');

  //     // let text1 = {
  //     //   "busRegNo": 'busRegNo',
  //     //   'messageText': 'messageText111',
  //     //   'messageTime':  new Date(),
  //     //   "from": 'mqtt'
  //     // };
  //     // setTimeout(() => {
  //     //   this.homeService.mqttMessageText.next(text1);
  //     // }, 1000);
  //     // var self = this;

  //     // setTimeout(() => {
  //     //   let count = 0;
  //     //   self.testMessageInterval = setInterval(function() {
  //     //     // console.log(count+' up');
  //     //     let text2 = {
  //     //       "busRegNo": 'busRegNo',
  //     //       'messageText': 'messageTex ' + count++,
  //     //       'messageTime':  new Date(),
  //     //       "from": 'mqtt'
  //     //     };
  //     //     // self.mqttMessageText['busRegNo'] = self.mqttMessageText['busRegNo'] === undefined ? [] : self.mqttMessageText['busRegNo'];
  //     //     // this.mqttMessageText['busRegNo'] = [];
  //     //     // self.mqttMessageText['busRegNo'].push(text2);
  //     //     self.homeService.mqttMessageText.next(text2);
  //     //   },2000);
  //     // //   this.setPredefinedData = [];
  //     // }, 1000);

  //     // setTimeout(() => {
  //     //   clearInterval(this.testMessageInterval);
  //     // },50000);

  //     // console.log(this.homeService.setPredefinedData);
  //   //end test data

  //   this.socketPredefined$.subscribe(
  //     (returnData) => {
  //       var busRegNo:string,tripId:string,routeId:string,driverId:string,latitude:string,longitude:string,messageType:string,messageNo:string,messageText:string,messageTime:string = '';
  //       var retStr = returnData.data.toString().replace('PreDefMessage(', '').replace(')', '').split(',');

  //       for (const message of retStr) {
  //         let messageData = message.split('=');
  //         let indexName = messageData[0].trim();
  //         if(indexName === 'busRegNo'){
  //           busRegNo = messageData[1];
  //         }
  //         if(indexName === 'tripId'){
  //           tripId = messageData[1];
  //         }
  //         if(indexName === 'routeId'){
  //           routeId = messageData[1];
  //         }
  //         if(indexName === 'driverId'){
  //           driverId = messageData[1];
  //         }
  //         if(indexName === 'latitude'){
  //           latitude = messageData[1];
  //         }
  //         if(indexName === 'longitude'){
  //           longitude = messageData[1];
  //         }
  //         if(indexName === 'messageType'){
  //           messageType = messageData[1];
  //         }
  //         if(indexName === 'messageNo'){
  //           messageNo = messageData[1];
  //         }
  //         if(indexName === 'messageText'){
  //           messageText = messageData[1];
  //         }
  //         if(indexName === 'messageTime'){
  //           messageTime = messageData[1];
  //         }
  //       }

  //       if(this.prefeinedMessagesList.indexOf(busRegNo) < 0) {
  //         this.prefeinedMessagesList.push(busRegNo);
  //         let message:predefinedMessageData = {
  //           "busRegNo": busRegNo,
  //           "trip": tripId,
  //           // "driverId": driverId,
  //           "routeId": routeId,
  //           "latitude": latitude,
  //           "longitude": longitude,
  //           "messageType": messageType,
  //           "messageNo": messageNo,
  //           "messageText": messageText,
  //           "messageTime": messageTime,
  //           "from": 'mqtt',
  //           "isBlinkRedYellow": true,
  //           "isVisible": false
  //         }
  //         this.homeService.setPredefinedData.push(message);
  //       }

  //       let newMessage = {
  //         "busRegNo": busRegNo,
  //         'messageText': messageText,
  //         'messageTime': moment(messageTime).format('HH:mm a'),//moment.utc(messageTime).local().format(),
  //         "from": 'mqtt'
  //       };

  //       setTimeout(() => {
  //         this.homeService.mqttMessageText.next(newMessage);
  //       }, 100);
  //     }
  //   )
  // }

  // initGoogleMap2() {

  //   this.glRoadmap = new GoogleLayer({
  //     mapTypeId: google.maps.MapTypeId.ROADMAP,
  //     name: 'gm-roadmap',
  //     visible: true
  //   });

  //   this.map.addLayer(this.glRoadmap);

  //   let olGM = new OLGoogleMaps({
  //     map: this.map
  //   }); // map is the Map instance

  //   let trafficLayer = new google.maps.TrafficLayer();
  //   const gmap = olGM.getGoogleMapsMap();
  //   trafficLayer.setMap(gmap);

  //   olGM.activate();

  //   // let layers = this.map.getLayers().getArray().slice();
  //   // console.log(layers);
  // }

  initGoogleMap() {
    this.currGoogleLayer = 'roadmap';
    if (!this.glRoadmap) {
      this.glRoadmap = new GoogleLayer({
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        name: 'gm-roadmap',
        visible: true,
      });
      this.map.addLayer(this.glRoadmap);
    }

    if (!this.glTerrain) {
      this.glTerrain = new GoogleLayer({
        mapTypeId: google.maps.MapTypeId.TERRAIN,
        name: 'gm-terrain',
        visible: false,
      });
      this.map.addLayer(this.glTerrain);
    }

    if (!this.glHybrid) {
      this.glHybrid = new GoogleLayer({
        mapTypeId: google.maps.MapTypeId.HYBRID,
        name: 'gm-hybrid',
        visible: false,
      });
      this.map.addLayer(this.glHybrid);
    }

    if (!this.glSatellite) {
      this.glSatellite = new GoogleLayer({
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        name: 'gm-satellite',
        visible: false,
      });
      this.map.addLayer(this.glSatellite);
    }

    if (!this.trafficLayer) {
      this.trafficLayer = new google.maps.TrafficLayer();
    }

    if (!this.olGM) {
      this.olGM = new OLGoogleMaps({
        map: this.map,
      }); // map is the Map instance

      this.olGM.activate();
    }
  }

  onChangeMapType(data) {
    this.currGoogleLayer = data;

    let layers = this.map.getLayers().getArray().slice();

    for (let row of layers) {
      let layerName = row.get('name');
      // this.map.removeLayer(row);
      if (row instanceof GoogleLayer) {
        if (layerName === 'gm-roadmap' && data === 'roadmap') {
          row.setVisible(true);
          // this.isTrafficLayer = true;
          if (
            layerName === 'gm-terrain' ||
            layerName === 'gm-hybrid' ||
            layerName === 'gm-satellite'
          ) {
            row.setVisible(false);
          }
        } else if (layerName === 'gm-terrain' && data === 'terrain') {
          row.setVisible(true);
          // this.isTrafficLayer = true;
          if (
            layerName === 'gm-roadmap' ||
            layerName === 'gm-hybrid' ||
            layerName === 'gm-satellite'
          ) {
            row.setVisible(false);
          }
        } else if (layerName === 'gm-satellite' && data === 'satellite') {
          row.setVisible(true);
          // this.isTrafficLayer = false;
          if (
            layerName === 'gm-roadmap' ||
            layerName === 'gm-hybrid' ||
            layerName === 'gm-terrain'
          ) {
            row.setVisible(false);
          }
        } else if (layerName === 'gm-hybrid' && data === 'hybrid') {
          row.setVisible(true);
          // this.isTrafficLayer = true;
          if (
            layerName === 'gm-roadmap' ||
            layerName === 'gm-terrain' ||
            layerName === 'gm-satellite'
          ) {
            row.setVisible(false);
          }
        } else {
          row.setVisible(false);
        }
      }
    }
  }

  trafficToggle(e) {
    var isTraffic = e.checked; //!this.isTraffic;

    const gmap = this.olGM.getGoogleMapsMap();
    if (isTraffic) {
      this.trafficLayer.setMap(gmap);
    } else {
      this.trafficLayer.setMap(null);
    }
  }

  initSwitch() {
    let self = this;

    this.mapService.getInitSwitch().then(data => {
      this.busSwitchListData = data;
      if (data['primary'] !== undefined) {
        // automatically set an initial route
        // let swtichData = {service: data['primary'][0].service, isOn: "", isChecked: true};
        // this.busSwitchListData['primary'][0].isOn = 'checked';
        // this.busSwitch(swtichData);
      }
      self.homeService.responsibilities = data;
    });
  }

  routerLadder(routeId) {
    return this.mapService.getRouteLadder(routeId).then(
      (data: any) => {
        this.ladders = data;

        if (this.ladders.direction) {
          var stopLen = 0;
          for (let direction of this.ladders.direction) {
            var stop = Object.keys(direction.stops);
            stopLen = stop.length - 1;
            direction['routeWidth'] = 100 / stopLen;
          }

          this.tripCompletionCalcService.addRoute(this.ladders);

          this.ladders.direction.forEach((x: any) => {
            x.stops.forEach((y: any) => {
              y.highlight = false;
            });
          });
          this.toggleVisible(routeId);
          // this.initMapRouteStartEnd();
          // console.log('Ladder Data: ', this.ladders);

          return { message: 'Created Route Ladder' };

          // setTimeout(() => {
          //   data.direction.forEach(element => {
          //     element.stops.forEach(stop => {
          //       this.addStopMarker(stop, '', element.value);
          //     });
          //   });
          // }, 200);
        }
        // this.isSwitchDisabled = false;

        // let ret:mqttData = {
        //   'trip': 'tripNo',
        //   'busCode': 'regNo',
        //   'lat': 1.304342,
        //   'lon': 103.838096,
        //   'drivenPercentage': 25,
        //   'direction' : '1',
        //   'nextStopId' : 'nextStopId',
        //   'prevStopId' : '',
        //   'status': '1',
        //   'isSelected': false,
        //   'degrees': '90',
        //   'isBusBunching' : false
        // };
        // //layers for the bus location
        // this.mqttService.mqttConnect(this.map, data, ret, ret);
      },
      e => {
        const { error } = e || {};
        // this._snackBar.open('Server error, please try again later.', null, {
        //   duration: 2000,
        // });
        // this.isSwitchDisabled = false;
        throw error;
      }
    );
  }

  vehicleLadderPosition() {
    // var direction1 = this.ladders.direction[0].stopIdx;
    var direction1 = this.ladders.direction[0].stops;

    this.busMqtt.forEach(element => {
      let prevStop = direction1.findIndex(
        x => x.stopCode === element.prevStopId
      );
      if (direction1[prevStop + 1].stopCode !== element.nextStopId) {
        let nextStop = direction1.findIndex(
          x => x.stopCode === element.nextStopId
        );

        // console.log('skipped stop ')
        // console.log(prevStop);
        // console.log(nextStop);
        element.drivenPercentage =
          element.drivenPercentage * (nextStop - prevStop);
      }
    });
  }

  addRouteMarker(busService) {
    return this.mapService.getBusRoute(busService).then(
      routeData => {
        if (routeData === null || !routeData.hasOwnProperty('route')) {
          return false;
        }
        let arrPaths = [];
        let busRouteDirection1 = [];
        let busRouteDirection2 = [];

        for (const row of routeData['direction']) {
          let busPath = row.path;
          this.isBirectional = false;

          if (busPath.length > 1) {
            this.isBirectional = true;

            let pathColorData = environment.map.pathColor;
            let busRouteData = busRouteDirection1;

            if (row.value != 1) {
              pathColorData = environment.map.pathOtherDirectionColor;
              busRouteData = busRouteDirection2;
            }

            for (const row1 of busPath) {
              let coord = row1.split(',');
              busRouteData.push([parseFloat(coord[1]), parseFloat(coord[0])]);
            }

            var routeGeom = new LineString(busRouteData).transform(
              'EPSG:4326',
              'EPSG:3857'
            );
            var routeFeature = new OlFeature({
              type: 'route',
              geometry: routeGeom,
              data: { direction: row.value },
            });

            var style = new Style({
              stroke: new Stroke({
                color: pathColorData,
                width: environment.map.pathWidth,
              }),
            });
            routeFeature.setStyle(style);
            arrPaths.push(routeFeature);
          }
        }

        let featuresList = [];

        for (const path of arrPaths) {
          featuresList.push(path); //route
        }

        const currLayer = this.findLayer(busService);

        if (
          currLayer &&
          (currLayer instanceof VectorLayer ||
            currLayer instanceof VectorImageLayer)
        ) {
          const currSource = currLayer.getSource();

          console.log('features', featuresList);

          currSource.addFeatures(featuresList);

          if (featuresList?.length > 0) {
            const extent = currSource.getExtent();
            this.map
              .getView()
              .fit(extent, { duration: 1000, padding: [20, 40, 180, 200] });
          }
        }
        // this.busSwitchOffAll(busService);

        // this.initMapRouteStartEnd();
      },
      err => {
        console.log(err);
      }
    );
  }

  findLayer(busService) {
    const currLayerName = 'VectorLayer' + busService;
    const layers = this.map.getLayers().getArray().slice();

    const serviceLayer =
      layers.find(layer => {
        const layerName = layer.get('name');
        if (layerName === currLayerName) {
          return true;
        }
        return false;
      }) ?? undefined;
    return serviceLayer;
  }

  createRouteVectorLayer(busService) {
    const featuresList = [];
    let vectorLayer = new VectorImageLayer({
      source: new VectorSource({
        features: featuresList,
      }),
      className: 'VectorLayer' + busService,
      properties: {
        name: 'VectorLayer' + busService,
      },
    });

    this.map.addLayer(vectorLayer);

    vectorLayer.setZIndex(1);
    vectorLayer.setVisible(true);
  }

  addStopMarker(stopData, markerData, direction = null) {
    let currLayer = 'VectorLayer' + stopData.route;
    let layers = this.map.getLayers().getArray().slice();
    let source;
    let newFeature;
    var bgMarker = '#00b5b5';

    if (direction === '2') {
      bgMarker = '#9e8f0b';
    }

    for (const rowLayer of layers) {
      let layerName = rowLayer.get('name');
      if (
        currLayer === layerName &&
        (rowLayer instanceof VectorLayer ||
          rowLayer instanceof VectorImageLayer)
      ) {
        source = rowLayer.getSource();

        var bus1Style = new Style({
          zIndex: +stopData.stopSequence + (direction === '2' ? 0 : 1000),
          image: new Circle({
            radius: 14,
            stroke: new Stroke({
              color: '#fff',
              width: 2,
            }),
            fill: new Fill({
              color: bgMarker,
            }),
          }),
          text: new Text({
            offsetY: 1,
            textAlign: 'center',
            justify: 'center',
            textBaseline: 'middle',
            text: stopData.stopSequence,
            fill: new Fill({
              color: '#fff',
            }),
            font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
            // stroke: new Stroke({
            //   color: bgMarker,
            //   width: 30,
            // }),
          }),
        });
        var bus2Style = new Style({
          text: new Text({
            offsetY: 0,
            text: stopData.pin.toString(),
            padding: [2, 5, 2, 5],
          }),
        });

        newFeature = new OlFeature({
          type: 'icon',
          geometry: new Point(
            transform(
              [parseFloat(stopData.longitude), parseFloat(stopData.latitude)],
              'EPSG:4326',
              'EPSG:3857'
            )
          ),
          name: 'stop-' + stopData.stopCode,
          description: stopData,
        });
        // newFeature.setStyle([bus1Style, bus2Style]);
        newFeature.setStyle([bus1Style]);
        source.addFeature(newFeature);
        this.map.render();
      }
    }
  }

  removeStopMarker(stopData) {
    let currLayer = 'VectorLayer' + stopData.route;
    let stopNameRemove = 'stop-' + stopData.stopCode;
    let layers = this.map.getLayers().getArray().slice();
    let source;

    for (const rowLayer of layers) {
      let layerName = rowLayer.get('name');
      if (
        currLayer === layerName &&
        (rowLayer instanceof VectorImageLayer ||
          rowLayer instanceof VectorLayer)
      ) {
        source = rowLayer.getSource();
        let features = source.getFeatures();

        for (const feature of features) {
          let featureName = feature.get('name');
          if (stopNameRemove === featureName) {
            source.removeFeature(feature);
          }
        }
      }
    }
  }

  onChangeProvider(provider) {
    if (provider === 'GoogleMap' && this.currGoogleLayer === '') {
      this.initGoogleMap();
    }
    this.provider = provider;
    this.layerMapSetVisibility(provider);
  }

  layerMapSetVisibility(mapProvider) {
    if (mapProvider === 'GoogleMap' && this.currGoogleLayer === 'roadmap') {
      this.glRoadmap.setVisible(true);
      this.glTerrain.setVisible(false);
      this.glSatellite.setVisible(false);
      this.glHybrid.setVisible(false);

      // this.isTrafficLayer = true;
      // this.isMapType = true;
    } else if (
      mapProvider === 'GoogleMap' &&
      this.currGoogleLayer === 'terrain'
    ) {
      this.glRoadmap.setVisible(false);
      this.glTerrain.setVisible(true);
      this.glSatellite.setVisible(false);
      this.glHybrid.setVisible(false);

      // this.isTrafficLayer = true;
      // this.isMapType = false;
    } else if (
      mapProvider === 'GoogleMap' &&
      this.currGoogleLayer === 'satellite'
    ) {
      this.glRoadmap.setVisible(false);
      this.glTerrain.setVisible(false);
      this.glSatellite.setVisible(true);
      this.glHybrid.setVisible(false);

      // this.isTrafficLayer = false;
      // this.isMapType = false;
    } else if (
      mapProvider === 'GoogleMap' &&
      this.currGoogleLayer === 'hybrid'
    ) {
      this.glRoadmap.setVisible(false);
      this.glTerrain.setVisible(false);
      this.glSatellite.setVisible(false);
      this.glHybrid.setVisible(true);

      // this.isTrafficLayer = true;
      // this.isMapType = false;
    }

    if (this.OSMLayer && mapProvider !== 'OpenStreetMap') {
      this.OSMLayer.setVisible(false);
    } else if (this.OSMLayer && mapProvider === 'OpenStreetMap') {
      this.OSMLayer.setVisible(true);
    }
  }

  /**
   *
   * @param e $event
   * @param data busData
   * @param action 1 inner action, 2 outer action
   * @param orientation ladder orientation, top, middle, bottom, right, left(soon)
   * @param headway direction value(routeA,routeB etc.) 1,2,3 etc.
   */
  onBusAction(
    e,
    data,
    action: number = 1,
    orientation: string = 'bottom',
    headway: string = '1'
  ) {
    // console.log(data);
    data.orientation = orientation;
    data.headway = headway;
    if (action === 1) {
      let busDataRoute = this.tripCompletionCalcService.findStop(
        data.nextStopId
      );
      let busPrevDataRoute = this.tripCompletionCalcService.findStop(
        data.prevStopId
      );
      data.stopCode = busDataRoute === undefined ? '' : busDataRoute.stopCode;
      data.stopDesc = busDataRoute === undefined ? '' : busDataRoute.stopDesc;
      data.stopName = busDataRoute === undefined ? '' : busDataRoute.stopName;

      data.fromCode =
        busPrevDataRoute === undefined ? '' : busPrevDataRoute.stopCode;
      data.fromDesc =
        busPrevDataRoute === undefined ? '' : busPrevDataRoute.stopDesc;
      data.fromName =
        busPrevDataRoute === undefined ? '' : busPrevDataRoute.stopName;
      data.route = busPrevDataRoute === undefined ? '' : busPrevDataRoute.route;

      this.onBusDetails(data);
      e.stopPropagation(); //to avoid call the child action
    } else {
      this.unpin(data, 1);
      e.stopPropagation(); //to avoid call the parent action
    }
  }

  onBusDetails(busData): void {
    if (this.busDetailsList.indexOf(busData.busCode) < 0) {
      this.busDetailsList.push(busData.busCode);
      // this.mapService.getBusDetailsEta(busData).then(
      //   (busEtaDataRes:any) => {
      // var busEtaDataRes = [];

      let busEtaData = busData; //(typeof busEtaDataRes !== 'object') ? busData : busEtaDataRes;
      this.pinCount++;
      busData.pin = this.pinCount;
      let routeName = '';

      if (this.busSwitchListData.primary) {
        for (const switchData of this.busSwitchListData.primary) {
          if (switchData.isOn === 'checked' || switchData.isOn === true) {
            routeName = switchData.routeName;
          }
        }
      }

      if (this.busSwitchListData.secondary) {
        for (const switchData of this.busSwitchListData.secondary) {
          if (switchData.isOn === 'checked' || switchData.isOn === true) {
            routeName = switchData.routeName;
          }
        }
      }
      // console.log(busData);
      // console.log(busEtaDataRes);

      busEtaData.routeName = routeName;
      busEtaData.routeId =
        this.ladders.routeId === undefined
          ? this.ladders.route[0]
          : this.ladders.routeId[0];
      busEtaData.status = busData.status;
      busEtaData.busRegNo = busData.busCode;
      busEtaData.lat = busData.lat;
      busEtaData.lon = busData.lon;
      busEtaData.nextStopId = busData.nextStopId;
      busEtaData.trip = busData.trip;

      // let componentFactory = this.CFR.resolveComponentFactory(BusDetailsComponent);
      // let currComponentRef: ComponentRef<BusDetailsComponent> = this.VCR.createComponent(componentFactory);
      // let currentComponent:any = currComponentRef.instance;

      // currentComponent.selfRef = currentComponent;
      // currentComponent.index = ++this.index;
      let passedData = {
        key: ++this.index, //busData.busRoute,
        value: busEtaData,
      };
      // currentComponent.data = passedData;

      // // prividing parent Component reference to get access to parent class methods
      // currentComponent.compInteraction = this;

      // add reference for newly created component
      // this.componentsReferences.push(currComponentRef);
      // console.log(this.VCR);

      this.busDetailsData.push(passedData);
      //   }
      // );
    }
  }

  // removeBusComponent(index, busCode, stopCode, key, route) {
  removeBusComponent(emittedData) {
    if (this.busDetailsData.length < 1) return;
    // console.log(this.componentsReferences.find(x => x.instance.index === index));

    // let currComponentRef = this.componentsReferences.filter(x => x.instance.index == 1)[0];
    // let currComponentRef = this.componentsReferences.find(x => x.instance.index === index);
    // let component: BusDetailsComponent = <BusDetailsComponent>currComponentRef.instance;

    // console.log(currComponentRef)
    // console.log(this.VCR)
    // let vcrIndex: number = this.VCR.indexOf(currComponentRef)

    let busDetailsInstance = this.busDetailsData.filter(
      x => x.key !== emittedData.index
    );
    this.busDetailsData = busDetailsInstance;
    // removing component from container
    // this.VCR.remove(vcrIndex);

    // this.componentsReferences = this.componentsReferences.filter(x => x.instance.index !== index);

    if (emittedData.busCode) {
      this.busDetailsList.splice(
        this.busDetailsList.indexOf(emittedData.busCode),
        1
      );
    }
    if (emittedData.stopCode) {
      this.busDetailsList.splice(
        this.busDetailsList.indexOf(emittedData.stopCode),
        1
      );
    }
    if (emittedData.key) {
      this.busDetailsList.splice(
        this.busDetailsList.indexOf(emittedData.key),
        1
      );
    }

    for (let direction of this.ladders.direction) {
      for (let stop of direction.stops) {
        if (stop.stopCode === emittedData.stopCode) {
          stop.pin = '';
        }
      }
    }

    if (this.ladders.buses) {
      for (let bus of this.ladders.buses) {
        if (bus.busCode === emittedData.busCode) {
          bus.pin = '';
        }
      }
    }

    let stopData = {
      route: emittedData.route,
      stopCode: emittedData.stopCode,
    };
    this.removeStopMarker(stopData);
  }

  preventHightlight: boolean = true;
  preventHightlightClickTimer: any;
  highlightBusStopOnLadderAndMap(stopData) {
    let self = this;

    this.highlightBusStopOnLadder(stopData.stopCode, stopData.stopSequence);

    this.preventHightlight = false;
    this.preventHightlightClickTimer = setTimeout(() => {
      if (!this.preventHightlight) {
        let currLayer = 'busStopIndicatorLayer';
        let layers = this.map.getLayers().getArray().slice();
        let source;
        let newFeature;

        for (const rowLayer of layers) {
          let layerName = rowLayer.get('name');
          if (
            currLayer === layerName &&
            (rowLayer instanceof VectorImageLayer ||
              rowLayer instanceof VectorLayer)
          ) {
            source = rowLayer.getSource();

            // var styles = [];

            // styles.push(new Style({
            //   image: new Icon(/** @type {module:ol/style/Icon~Options} */ ({
            //     src: 'assets/images/icon/map-marker-blue-2.png',
            //     anchor: [0.5, 1]
            //   }))
            // }));

            // var styles = new Style({
            //   image: new Icon(/** @type {module:ol/style/Icon~Options} */ ({
            //     src: 'assets/images/icon/map-marker-blue-2.png',
            //     anchor: [0.5, 1]
            //   }))
            // });

            // var stopOnDisplay = self.openedRoute.filter(x => x.stopCode === stopData.stopCode).length > 0;

            // if (!stopOnDisplay) {
            //   styles.push(new Style({
            //     text: new Text({
            //       offsetY: -42,
            //       // offsetX: 5,
            //       text: stopData.stopName,
            //       fill: new Fill({
            //         color: '#c3c3c3'
            //       }),
            //       backgroundFill: new Fill({
            //         color: '#2a2b30'
            //       }),
            //       padding: [2, 5, 2, 5]
            //     })
            //   }));

            let styles = new Style({
              image: new Icon(
                /** @type {module:ol/style/Icon~Options} */ {
                  anchor: [0.5, 25],
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'pixels',
                  opacity: 1,
                  src: 'assets/images/icon/map-marker-red.png',
                }
              ),
              text: new Text({
                offsetY: -39,
                text: stopData.stopName,
                fill: new Fill({
                  //   color: '#fff'
                  color: '#000',
                }),
                padding: [5, 5, 5, 5],
                font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
                // stroke: new Stroke({
                //   //   color: '#797979',
                //   color: '#49d4e3',
                //   width: 20,
                // }),
              }),
              // zIndex: 4
            });
            // }

            // rounded background
            const tempText = stopData.stopName.replace(/ /g, '');
            const squareText = '\u25A0'.repeat(tempText.length);
            const squareBgTextStyle = new Style({
              text: new Text({
                text: squareText,
                offsetY: -40,
                fill: new Fill({
                  color: '#f0f0f0',
                }),
                textAlign: 'center',
                justify: 'center',
                textBaseline: 'middle',
                font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
                stroke: new Stroke({
                  color: '#f0f0f0',
                  width: 21,
                }),
              }),
            });

            newFeature = new OlFeature({
              type: 'icon',
              geometry: new Point(
                transform(
                  [
                    parseFloat(stopData.longitude),
                    parseFloat(stopData.latitude),
                  ],
                  'EPSG:4326',
                  'EPSG:3857'
                )
              ),
              name: 'stop-' + stopData.stopCode,
              description: stopData,
            });
            newFeature.setStyle([squareBgTextStyle, styles]);
            source.addFeature(newFeature);
          }
        }

        this.viewAnimate.animate({
          center: fromLonLat([stopData.longitude, stopData.latitude]),
          duration: 1000,
          zoom: 16,
        });
      }
    }, 500);
  }

  highlightBusStopOnLadder(stopCode, stopSequence?) {
    this.ladders.direction.forEach((x: any) => {
      x.stops.forEach((y: any) => {
        if (y.stopCode === stopCode) {
          // if (stopSequence) {
          //   if (y.stopSequence === stopSequence) {
          //     y.highlight = true;
          //     this.busStopHiglighted.push(y);
          //   }
          // } else {
          //   y.highlight = true;
          //   this.busStopHiglighted.push(y);
          // }
          y.highlight = true;
          this.busStopHiglighted.push(y);
        }
      });
    });
  }

  unhighlightBusStopOnLadderAndMap(stopData) {
    this.unhighlightBusStopOnLadder();

    this.preventHightlight = true;
    clearTimeout(this.preventHightlightClickTimer);

    let currLayer = 'busStopIndicatorLayer';
    let stopNameRemove = 'stop-' + stopData.stopCode;
    // let layers = this.map.getLayers().getArray().slice();
    // let source;

    // for (const rowLayer of layers) {
    //   let layerName = rowLayer.get('name');
    //   if(currLayer === layerName) {
    //     source = rowLayer.getSource();
    //     let features = source.getFeatures();

    //     for (const feature of features) {
    //       let featureName = feature.get('name');
    //       if(stopNameRemove === featureName) {
    //         source.removeFeature(feature);
    //       }
    //     }
    //   }
    // }

    var isLayerExist = this.mapService.isLayerExist(this.map, currLayer);
    if (isLayerExist) {
      // let features = isLayerExist.getSource().getFeatures();
      // features.forEach(element => {
      this.mapService.removeLayerFeature(isLayerExist, stopNameRemove); //element.get('name'));
      // });
      // this.mapService.removeLayerByLayerName(this.map, 'VectorLayer'+busRoute);
    }
  }

  unhighlightBusStopOnLadder() {
    if (this.busStopHiglighted.length > 0) {
      this.busStopHiglighted.forEach(x => {
        x.highlight = false;
      });

      this.busStopHiglighted = [];
    }
  }

  zoomBlinkBusStop(data) {
    let stopData = data.value;
    clearInterval(this.mapMarkerBlink[stopData.stopCode]);

    let iColor = 0;
    const self = this;

    this.viewAnimate.animate({
      center: fromLonLat([stopData.longitude, stopData.latitude]),
      duration: 1000,
      zoom: 16,
    });

    self.mapMarkerBlink[stopData.stopCode] = setInterval(function () {
      if (iColor === 0) {
        iColor = 1;
      } else {
        iColor = 0;
      }
      self.removeStopMarker(stopData);
      self.addStopMarker(stopData, self.blinkColors[iColor]);
    }, 500);
    setTimeout(() => {
      clearInterval(self.mapMarkerBlink[stopData.stopCode]);
    }, 5000);
  }

  zoomBusLocation(data) {
    var busData = data.value;
    this.viewAnimate.animate({
      center: fromLonLat([busData.lon, busData.lat]),
      duration: 1000,
      zoom: 16,
    });
  }

  /**
   *
   * @param stopData
   * @param orientation
   * @param headway
   *
   * disabled feature
   */
  onStopDetails(stopData, orientation, headway): void {
    return;
    // this.mapService.getStopDetailsByStopCode(stopData.stopCode).then(
    //   (resRouteData:any) => {

    //     stopData.orientation = orientation;
    //     stopData.headway = headway;
    //     stopData.arrivals = resRouteData.arrivals;
    //     if(this.busDetailsList.indexOf(stopData.stopCode)  < 0){ // && stopData.isMajorStop === 'true') {
    //       for (let direction of this.ladders.direction) {
    //         for (let stop of direction.stops) {
    //           if(stop.stopCode === stopData.stopCode) {
    //             this.pinCount++;
    //             stop.pin = this.pinCount;
    //           }
    //         }
    //       }
    //       stopData.pin = this.pinCount;
    //       stopData.route = stopData.route;

    //       let componentFactory = this.CFR.resolveComponentFactory(StopDetailsComponent);
    //       let currComponentRef: ComponentRef<StopDetailsComponent> = this.VCR.createComponent(componentFactory);
    //       let currentComponent = currComponentRef.instance;

    //       currentComponent.selfRef = currentComponent;
    //       currentComponent.index = ++this.index;

    //       let passedData = {
    //         'key': stopData.stopCode,
    //         'value': stopData
    //       }
    //       currentComponent.data = passedData;

    //       // prividing parent Component reference to get access to parent class methods
    //       currentComponent.compInteraction = this;

    //       // add reference for newly created component
    //       this.componentsReferences.push(currComponentRef);

    //       this.busDetailsList.push(stopData.stopCode);

    //       let iColor = 0;
    //       const self = this;

    //       self.mapMarkerBlink[stopData.stopCode] = setInterval(function() {
    //         if(iColor === 0) {
    //           iColor = 1;
    //         }
    //         else {
    //           iColor = 0;
    //         }
    //         self.removeStopMarker(stopData);
    //         self.addStopMarker(stopData, self.blinkColors[iColor]);
    //       }, 500);

    //       setTimeout(() => {
    //         // self.removeStopMarker(stopData);
    //         clearInterval(self.mapMarkerBlink[stopData.stopCode]);
    //       }, 5000);
    //     }
    // });
  }

  onRouteDetails(busData): void {
    let route = busData.routeId[0];
    this.mapService.getRouteDetailsByRoute(route).then((routeData: any) => {
      if (this.busDetailsList.indexOf(route) < 0) {
        this.pinCount++;
        routeData.pin = this.pinCount;
        routeData.route = route;

        let componentFactory = this.CFR.resolveComponentFactory(
          RouteDetailsComponent
        );
        let currComponentRef: ComponentRef<RouteDetailsComponent> =
          this.VCR.createComponent(componentFactory);
        let currentComponent: any = currComponentRef.instance;

        currentComponent.selfRef = currentComponent;
        currentComponent.index = ++this.index;
        let passedData = {
          key: route,
          value: routeData,
        };
        currentComponent.data = passedData;

        // prividing parent Component reference to get access to parent class methods
        currentComponent.compInteraction = this;

        // add reference for newly created component
        this.componentsReferences.push(currComponentRef);

        this.busDetailsList.push(route);
      }
    });
  }

  busSwitchOffAll(busRoute) {
    this.busMqtt = {};
    for (const i in this.busSwitchListData) {
      for (const row of this.busSwitchListData[i]) {
        if (row.service !== busRoute) {
          row.isOn = '';
        }
      }
    }

    let layers = this.map.getLayers().getArray().slice();
    for (let row of layers) {
      let rowName = row.get('name');
      if (rowName !== undefined) {
        if (rowName !== 'VectorLayer' + busRoute) {
          if (
            row instanceof GoogleLayer === false &&
            rowName !== 'busStopIndicatorLayer' &&
            (row instanceof VectorLayer || row instanceof VectorImageLayer)
          ) {
            let features = row.getSource().getFeatures();
            features.forEach(element => {
              this.mapService.removeLayerFeature(row, element.get('name'));
            });
            // this.mapService.removeLayerByLayerName(this.map, 'VectorLayer'+busRoute);
            this.map.removeLayer(row);
          }
        }
      }
    }
  }

  isSwitch: boolean = false;
  routeName: string = '';
  isSwitchDisabled: boolean = false;
  async busSwitch(busRouteData) {
    this.isSwitch = false;
    this.isSwitchDisabled = true;

    if (environment.isRouteNameUse) {
      //config file
      for (const service of this.busSwitchListData.primary) {
        if (service.isOn === 'checked') {
          this.routeName = service.routeName;
          break;
        }
      }
    }

    let busRoute = busRouteData.service;
    let layerName = 'VectorLayer' + busRoute;

    if (this.socket$) {
      this.socket$.unsubscribe();
    }

    this.geoFencesLayer.setVisible(false);

    let isShowRoute = busRouteData?.isChecked ?? false;
    if (!this.isSwitch) {
      let isExist = this.isLayerExist(layerName);

      if (!isExist) {
        this.busSwitchOffAll(busRoute);

        this.routerLadder(busRoute)
          .then(response => {
            // console.log('router ladder response', response);
            this.createRouteVectorLayer(busRoute);
            this.addRouteMarker(busRoute)
              .then(resp => {
                // console.log('addRouteMarker response', resp);
                this.ladders.direction.forEach(element => {
                  element.stops.forEach(stop => {
                    this.addStopMarker(stop, '', element.value);
                  });
                });

                this.isSwitch = true;
                this.isSwitchDisabled = false;

                // turn on geofence layer
                if (busRouteData.service === '285') {
                  this.geoFencesLayer.setVisible(true);
                } else {
                  this.geoFencesLayer.setVisible(false);
                }
              })
              .catch(e => {
                console.error('addRouteMarker error', e);
                throw e;
              });
          })
          .catch(e => {
            console.error('router ladder error', e);

            this._snackBar.open(
              `Server error. Failed to load ${busRoute}.`,
              null,
              {
                duration: 3000,
                panelClass: 'custom-snack-bar-panel-error',
              }
            );
            this.busSwitchOffAll(busRoute);
            busRouteData.isChecked = false;
            busRouteData.isOn = '';
            this.zoomOutSg();
            this.isSwitchDisabled = false;
            this.isSwitch = false;
          });
      } else {
        console.log(4);
        this.busSwitchOffAll(busRoute);

        this.zoomOutSg();
        this.isSwitchDisabled = false;

        var isLayerExist = this.mapService.isLayerExist(
          this.map,
          'VectorLayer' + busRoute
        );
        if (isLayerExist) {
          let features = isLayerExist.getSource().getFeatures();
          features.forEach(element => {
            this.mapService.removeLayerFeature(
              isLayerExist,
              element.get('name')
            );
          });
          this.mapService.removeLayerByLayerName(
            this.map,
            'VectorLayer' + busRoute
          );
        }
      }

      let i = 0;
      this.pinCount = 0; //reset pin count

      for (let busDetails of this.componentsReferences) {
        let route = busDetails.instance.data.value.route;

        let currComponentRef = this.componentsReferences.filter(
          x => x.instance.data.value.route === route
        )[i];
        let vcrIndex: number = this.VCR.indexOf(currComponentRef);

        // removing component from container
        this.VCR.remove(vcrIndex);
        this.busDetailsList.splice(this.busDetailsList.indexOf(busRoute), 1);

        i++;
      }
      this.componentsReferences = [];

      if (isShowRoute) {
        this.toggleVectorLayer(layerName);
        this.listenServiceRouteMqttNode(busRouteData);

        busRouteData.type = MqttType.eVehiclePosition;
        this.mqttRequest(busRouteData);
      }

      this.openedRouteList = [];
      this.openedRoute = [];

      this.homeService.publish({ type: 'close', src: 'switch' });

      this.busDetailsData = []; //clear open bus details

      // testdata to show a bus:
      // const testData = {
      //   trip: '0',
      //   busCode: 'SBST3408A',
      //   lat: 1.314793,
      //   lon: 103.764305,
      //   drivenPercentage: Math.floor(Math.random() * 100),
      //   direction: '1',
      //   prevStopId: '17171',
      //   status: '0',
      //   isSelected: false,
      //   degrees: '0',
      //   isBusBunching: false,
      //   driverId: 'TESTBLOCK',
      //   blockId: '32850000',
      //   nextStopId: '17009',
      //   nextstopeta: '',
      //   secondnextstopId: '',
      //   secondnextstopeta: '',
      //   thirdnextstopId: '',
      //   thirdnextstopeta: '',
      //   ratio: 0,
      //   value: '',
      //   pin: '',
      //   expiryDate: '2023-10-13T01:48:50.896Z',
      // };
      // const testRouteData = {
      //   service: '285',
      //   isOn: 'checked',
      //   routeName: '285',
      //   isChecked: true,
      //   type: 'vehiclePosition',
      // };
      // setTimeout(() => {
      //   this.mqttService.mqttConnect(
      //     this.map,
      //     testRouteData,
      //     testData,
      //     testData
      //   );
      // }, 2000);
    }
  }

  toggleVisible(busRoute) {
    let row = this.ladders;
    let route = row.routeId === undefined ? row.route[0] : row.routeId[0];
    if (route === busRoute && row.isVisible === 'false') {
      row.isVisible = 'true';
    } else if (route === busRoute) {
      row.isVisible = 'false';
    }
    if (row.direction) {
      for (let direction of row.direction) {
        for (let stop of direction.stops) {
          // console.log(stop);
          if (route === busRoute) {
            stop.pin = '';
          }
        }
      }
    }
  }

  toggleVectorLayer(layerName) {
    let layers = this.map.getAllLayers();
    for (let row of layers) {
      let layerBusCode = layerName;
      let rowName = row.get('name');
      if (layerBusCode === rowName) {
        if (row.getVisible()) {
          row.setVisible(false);
        } else if (!row.getVisible()) {
          row.setVisible(true);
        }
      }
    }
  }

  isLayerExist(layerName) {
    let layers = this.map.getAllLayers();
    for (let row of layers) {
      let rowName = row.get('name');
      if (layerName === rowName) {
        return true;
      }
    }
    return false;
  }

  //1 buses
  //2 stops
  //3 routes
  unpin(data, action) {
    if (this.VCR.length < 0) return;

    let i = 0;

    if (this.mapMarkerBlink[data.stopCode]) {
      clearInterval(this.mapMarkerBlink[data.stopCode]);
    }
    var route = data.route[0];

    for (let busDetails of this.componentsReferences) {
      let pin = busDetails.instance.data.value.pin;
      let busCode = busDetails.instance.data.value.busCode;
      let key = busDetails.instance.data.key;

      if (pin && pin === data.pin && busCode === data.busCode) {
        let currComponentRef = this.componentsReferences[i]; //.filter(x => x.instance.data.value.busCode == data.busCode)[i];
        // let currComponentRef = this.componentsReferences.filter(x => x.instance.data.key == key)[0];
        let vcrIndex: number = this.VCR.indexOf(currComponentRef);
        this.VCR.remove(vcrIndex);
      } else if (action === 3 && pin === data.pin && busCode === data.busCode) {
        let currComponentRef = this.componentsReferences[i]; //.filter(x => x.instance.data.value.busCode == data.busCode)[i];
        // let currComponentRef = this.componentsReferences.filter(x => x.instance.data.key == key)[0];
        let vcrIndex: number = this.VCR.indexOf(currComponentRef);
        this.VCR.remove(vcrIndex);
      } else if (action === 3 && key === route) {
        let currComponentRef = busDetails;
        let vcrIndex: number = this.VCR.indexOf(currComponentRef);
        this.VCR.remove(vcrIndex);
      }
      i++;
    }

    if (action === 1) {
      this.busDetailsList.splice(this.busDetailsList.indexOf(data.busCode), 1);
    } else if (action === 2) {
      this.busDetailsList.splice(this.busDetailsList.indexOf(data.stopCode), 1);
    } else if (action === 3) {
      this.busDetailsList.splice(this.busDetailsList.indexOf(route), 1);
    }

    for (let direction of this.ladders.direction) {
      for (let stop of direction.stops) {
        if (stop.stopCode === data.stopCode) {
          stop.pin = '';
        }
      }
    }

    for (let busMqtt of this.busMqtt) {
      if (busMqtt.busCode === data.busCode) {
        busMqtt.pin = '';
      }
    }

    this.removeStopMarker(data);
  }

  routeHighlighted = [];
  mapOptionsHover = [];
  mapClick(evt) {
    var me = this;
    this.map.on('pointermove', e => {
      if (e.dragging) {
        return;
      }
      if (me.routeHighlighted) {
        me.routeHighlighted.forEach(element => {
          let pathColorData = environment.map.pathColor;
          if (element.get('data').direction !== 1) {
            pathColorData = environment.map.pathOtherDirectionColor;
          }

          element.setStyle(
            new Style({
              stroke: new Stroke({
                color: pathColorData,
                width: environment.map.pathWidth,
              }),
              text: new Text({
                offsetY: -18,
                text: element.values_.message
                  ? element.values_.message.location
                  : '',
                fill: new Fill({
                  color: '#fff',
                }),
                padding: [5, 5, 5, 5],
                font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
                stroke: new Stroke({
                  color: '#797979',
                  width: 20,
                }),
              }),
            })
          );
        });
        me.routeHighlighted = [];
      }

      if (me.mapOptionsHover) {
        me.mapOptionsHover.forEach(element => {
          let dataObj = element.values_.dataObj;
          element.setStyle(
            new Style({
              image: new Icon(
                /** @type {module:ol/style/Icon~Options} */ {
                  anchor: [0.5, 25],
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'pixels',
                  opacity: 1,
                  // src: 'assets/images/icon/roadwork2-32.png'
                  src: dataObj.imgSrc,
                }
              ),
            })
          );
        });
        me.mapOptionsHover = [];
      }

      var pixel = e.map.getEventPixel(e.originalEvent);
      var hit = false;
      var stopCode = undefined;

      // let layers = me.map.getLayers().getArray().slice();

      e.map.forEachFeatureAtPixel(
        pixel,
        function (feature: OlFeature, layer) {
          let layerName = layer.get('name') || '';
          let featureName = feature.get('name');
          let routeName =
            me.ladders.routeId === undefined
              ? me.ladders.route[0]
              : me.ladders.routeId[0];

          // if(layerName && layerName.includes("VectorLayerMqtt")) {
          //   hit = true;
          //   stopCode = feature.values_.description.stopCode;
          // }

          // else
          if (featureName && featureName.includes('stop')) {
            hit = true;
            stopCode = feature.get('description').stopCode;
          } else if (layerName === 'VectorLayer' + routeName) {
            hit = true;
            let pathColorData = environment.map.pathColor;
            if (feature.get('data') === undefined) {
              return false;
            } else if (feature.get('data').direction !== 1) {
              pathColorData = environment.map.pathOtherDirectionColor;
            }

            feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: pathColorData,
                  width: environment.map.pathWidthHover,
                }),
                text: new Text({
                  offsetY: -18,
                  text: feature.get('message')
                    ? feature.get('message').location
                    : '',
                  fill: new Fill({
                    color: '#fff',
                  }),
                  padding: [5, 5, 5, 5],
                  font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
                  stroke: new Stroke({
                    color: '#797979',
                    width: 20,
                  }),
                }),
              })
            );
            me.routeHighlighted.push(feature);
          } else if (layerName.includes('VectorLayerMapOptions-')) {
            hit = true;

            let iconStyle = new Style({
              image: new Icon(
                /** @type {module:ol/style/Icon~Options} */ {
                  anchor: [0.5, 25],
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'pixels',
                  opacity: 1,
                  src: feature.get('dataObj').imgSrc,
                }
              ),
              text: new Text({
                offsetY: -37,
                text: feature.get('dataObj').message.toString(),
                fill: new Fill({
                  color: '#fff',
                }),
                // backgroundFill: new Fill({
                //     color: '#2a2b30'
                // }),
                // font: '16px Calibri,sans-serif',
                padding: [5, 5, 5, 5],

                font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
                stroke: new Stroke({
                  color: '#000',
                  width: 20,
                }),
              }),
              zIndex: 4,
            });
            feature.setStyle(iconStyle);
            me.mapOptionsHover.push(feature);
          }

          // if(layerName && layerName.includes("VectorLayer")) {
          //   console.log(layerName);
          //   feature.setStyle(new Style({
          //     stroke: new Stroke({
          //       color: environment.map.pathColor,
          //       width: 2
          //     }),
          //     // image: new Circle({
          //     //   radius: 5.5,
          //     //   stroke: new Stroke({
          //     //     color: '#0d47a1',
          //     //     width: 2
          //     //   })
          //     // })
          //   }));
          //   me.routeHighlighted.push(feature);
          // }
        },
        {
          hitTolerance: 3,
        }
      );

      e.map.forEachFeatureAtPixel(
        pixel,
        function (feature, layer) {
          let layerName = layer.get('name');
          // let featureName = feature.get('name');
          // let routeName = me.ladders.routeId === undefined ? me.ladders.route[0] : me.ladders.routeId[0];

          if (layerName && layerName.includes('VectorLayerMqtt')) {
            hit = true;
            stopCode = feature.get('description').stopCode;
          }
        },
        {
          hitTolerance: 25,
        }
      );

      e.map.getTargetElement().style.cursor = hit ? 'pointer' : '';

      if (stopCode) {
        this.highlightBusStopOnLadder(stopCode);
      } else {
        this.unhighlightBusStopOnLadder();
      }
    });

    this.map.on('singleclick', evt => {
      let feature = this.map.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          return feature;
        },
        {
          hitTolerance: 25,
        }
      );
      if (feature) {
        let featureName = feature.get('name');

        if (featureName === undefined) {
          return false;
        } else if (featureName.includes('stop')) {
          this.openBusStopEvent(feature);
        } else if (feature) {
          this.onBusAction(evt, feature.get('description'));
        }

        // console.log(feature);
      }
      evt.preventDefault(); //add this to avoid bubling
    });
  }

  predefinedMessageZoom(data) {
    this.viewAnimate.animate({
      center: fromLonLat([
        parseFloat(data.longitude),
        parseFloat(data.latitude),
      ]),
      duration: 1000,
      zoom: 16,
    });
  }

  zoomOutSg() {
    this.viewAnimate.animate({
      center: fromLonLat(environment.map.locationLonLat),
      duration: 1000,
      zoom: 12,
    });
  }

  // addBusOnMap(dataBus) {
  //   let busColor = '';
  //   if(dataBus.status === '0') {
  //     busColor = ColorData.eGreen;
  //   }
  //   else if(dataBus.status === '-1') {
  //     busColor = ColorData.eYellow;
  //   }
  //   else {
  //     busColor = ColorData.eRed;
  //   }

  //   var bus1 = new OlFeature({
  //     type: 'icon',
  //     geometry: new Point(transform([parseFloat(dataBus.longitude),parseFloat(dataBus.latitude)], 'EPSG:4326', 'EPSG:3857')),
  //     name: 'pdm-' + dataBus.busRegNo,
  //     description: dataBus
  //   });

  //   var bus1Style = new Style({
  //     image: new Circle({
  //       radius: 6,
  //       stroke: new Stroke({
  //         color: '#c3c3c3',
  //         width: 2,
  //         padding: 2
  //       }),
  //       fill: new Fill({
  //         color: busColor
  //       })
  //     }),
  //     text: new Text({
  //       offsetY: -15,
  //       text: dataBus.busRegNo,
  //       fill: new Fill({
  //         color: '#c3c3c3'
  //       }),
  //       backgroundFill: new Fill({
  //         color: '#2a2b30'
  //       }),
  //       padding: [5,5,2,5]
  //     })
  //   });

  //   bus1.setStyle(bus1Style);

  //   return bus1;
  // }

  initMapRouteStartEnd() {
    setTimeout(() => {
      let from = this.ladders.from;
      let to = this.ladders.to;
      if (from) {
        let firstArray = [from.longitude, from.latitude];
        let lastArray = [to.longitude, to.latitude];
        let currLayer: VectorLayer<any> | VectorImageLayer<any> | string = '';

        let route =
          this.ladders.route === undefined
            ? this.ladders.routeId[0]
            : this.ladders.route[0];
        let layers = this.map.getLayers().getArray().slice();
        for (let row of layers) {
          let rowName = row.get('name');
          if (
            rowName === 'VectorLayer' + route &&
            (row instanceof VectorImageLayer || row instanceof VectorLayer)
          ) {
            currLayer = row;
          }
        }

        // var firstMapData = {
        //   'imgSrc': 'assets/images/icon/warning-32.png',
        //   'lat': from.latitude,
        //   'lon': from.longitude,
        //   'name': 'alarmLayer',
        //   'message': 'Commonwealth Avenue'
        // }
        // this.mapService.addMapMarkWithText(currLayer, firstMapData);

        var startMarker = new OlFeature({
          type: 'icon',
          geometry: new Point(transform(firstArray, 'EPSG:4326', 'EPSG:3857')),
          message: from,
        });

        var endMarker = new OlFeature({
          type: 'icon',
          geometry: new Point(transform(lastArray, 'EPSG:4326', 'EPSG:3857')),
          message: to,
        });

        var startMarkerStyle = new Style({
          image: new Circle({
            radius: 10,
            stroke: new Stroke({
              color: '#c3c3c3',
              width: 2,
            }),
            fill: new Fill({
              color: '#04c7c7',
            }),
          }),
          text: new Text({
            offsetY: -18,
            text: this.ladders.from.location,
            // fill: new Fill({
            //   color: '#c3c3c3'
            // }),
            // backgroundFill: new Fill({
            //   color: '#2a2b30'
            // }),
            // padding: [5,5,2,5]
            fill: new Fill({
              color: '#fff',
            }),
            padding: [5, 5, 5, 5],
            font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
            stroke: new Stroke({
              color: '#797979',
              width: 20,
            }),
          }),
        });

        var endMarkerStyle = new Style({
          image: new Circle({
            radius: 10,
            stroke: new Stroke({
              color: '#c3c3c3',
              width: 2,
            }),
            fill: new Fill({
              color: '#04c7c7',
            }),
          }),
          text: new Text({
            offsetY: -18,
            text: this.ladders.to.location,
            // fill: new Fill({
            //   color: '#c3c3c3'
            // }),
            // backgroundFill: new Fill({
            //   color: '#2a2b30'
            // }),
            // padding: [5,5,2,5]
            fill: new Fill({
              color: '#fff',
            }),
            padding: [5, 5, 5, 5],
            font: 'bold 12px Roboto, Helvetica Neue, Verdana, Helvetica, Arial, sans-serif',
            stroke: new Stroke({
              color: '#797979',
              width: 20,
            }),
          }),
        });

        startMarker.setStyle(startMarkerStyle);
        endMarker.setStyle(endMarkerStyle);
        if (
          currLayer &&
          (currLayer instanceof VectorImageLayer ||
            currLayer instanceof VectorLayer)
        ) {
          currLayer.getSource().addFeature(startMarker);
          currLayer.getSource().addFeature(endMarker);
        }
      }
      // this.isSwitchDisabled = false;
    }, 1000);
  }

  // closeMessagePanel(predefinedData) {
  //   this.prefeinedMessagesList.splice(this.prefeinedMessagesList.indexOf(predefinedData.busRegNo), 1);
  //   var index = 0;
  //   for (const msg of this.homeService.setPredefinedData) {
  //     if(msg.busRegNo === predefinedData.busRegNo) {
  //       this.homeService.setPredefinedData.splice(this.homeService.setPredefinedData.indexOf(index), 1);
  //     }
  //     index++;
  //   }
  // }

  // showMessageList(busData){
  //   var busValue = busData.value;
  //   if(this.prefeinedMessagesList.indexOf(busValue.busRegNo) < 0) {
  //     let message:predefinedMessageData = {
  //       "busRegNo": busValue.busRegNo,
  //       "trip": busValue.trip,
  //       // "driverId": busValue.driverId,
  //       "routeId": busValue.routeId,
  //       "latitude": busValue.lat,
  //       "longitude": busValue.lon,
  //       "messageType": "",
  //       "messageNo": "",
  //       "messageText": "",
  //       "messageTime": "" ,
  //       "from": "",
  //       "isBlinkRedYellow": false,
  //       "isVisible": true
  //     }
  //     this.homeService.setPredefinedData.push(message);
  //     this.prefeinedMessagesList.push(busValue.busRegNo);
  //   }
  //   else {
  //     for (const message of this.homeService.setPredefinedData) {
  //       if(message.busRegNo === busValue.busRegNo) {
  //         message.isVisible = true;
  //       }
  //     }
  //   }
  // }

  openAllRoutes(direction: number) {
    this.preventSingleClick = false;
    this.preventSingleClickTimer = setTimeout(() => {
      if (!this.preventSingleClick) {
        var stopList =
          this.ladders.direction[direction - 1] === undefined
            ? this.ladders.direction[0].stops
            : this.ladders.direction[direction - 1].stops;
        var stopId =
          this.ladders.direction[direction - 1] === undefined
            ? this.ladders.direction[0].stopIdx
            : this.ladders.direction[direction - 1].stopIdx;
        var pinCount = 0;

        for (let direction of this.ladders.direction) {
          for (const stop of direction.stops) {
            stop.pin = '';
            this.removeStopMarker(stop);
          }
        }

        for (let stop of stopList) {
          pinCount++;
          stop.pin = pinCount;
          this.addStopMarker(stop, this.blinkColors[0]);
        }
        this.openedRoute = stopList;
        this.openedRouteList = stopId;

        for (const stop of this.openedRoute) {
          stop.isOpened = false;
        }

        this.homeService.publish({ type: 'open', direction: direction });
      }
    }, 200);
  }

  closeAllRoutes(direction: number) {
    this.preventSingleClick = true;
    clearTimeout(this.preventSingleClickTimer);
    // removing all stops
    // for (let direction of this.ladders.direction) {
    //   for (const stop of direction.stops) {
    //     stop.pin = "";
    //     this.removeStopMarker(stop);
    //   }
    // }
    this.openedRouteList = [];
    this.openedRoute = [];

    this.homeService.publish({ type: 'close', src: 'eta' });
  }

  openBusStopEvent(data) {
    var stopCode = data.values_.description.stopCode;
    for (const stop of this.openedRoute) {
      if (stop.stopCode === stopCode) {
        stop.isOpened = true;
        this.homeService.publish({ type: 'stop', stop: stop });
      }
    }
  }

  // voipData:any = {};
  // isShowVoidPanel: boolean = false;
  // initVoip(busData) {
  //   var postData:LogsData = {
  //     "busRegNo":busData.value.busCode,
  //     "routeId":busData.value.route,
  //     "tripId":'',
  //     "latitude":busData.value.lat,
  //     "longitude":busData.value.lon,
  //     "timeInSeconds":moment().unix(),
  //     'msgEvent': 'make'
  //   };

  //   this.voipService.getVehicleSipNumber(busData.value.busCode).subscribe(
  //     respSip => {
  //       if(respSip.sipId === '-') {
  //         this._snackBar.open('No SIP number available on this vehicle '+busData.value.busCode, null, {
  //           duration: 5000,
  //         });
  //         return false;
  //       }
  //       else {
  //         this.voipService.logVoip(postData).then(dataResp => {
  //           this.isShowVoidPanel = true;
  //           this.voipData = postData;
  //           this.voipData.stattus = 'calling';

  //           this.voipService.doCall("sip:"+respSip.sipId+"@10.12.0.101");
  //         });
  //       }
  //     }
  //   )

  // }

  // initIncomingData() {
  //   this.isShowVoidPanel = true;
  //   let testData = {
  //     'busRegNo' : '',
  //     'stattus': 'calling'
  //   };
  //   this.voipData = testData;
  // }

  // voipHangUp() {
  //   this.voipData.msgEvent = 'drop';
  //   this.voipData.timeInSeconds = moment().unix();
  //   this.voipService.logVoip(this.voipData).then(dataResp => {
  //     this.voipService.doHangup();
  //   });
  // }

  // voipAnswer() {
  //   this.voipData.msgEvent = 'attend';
  //   this.voipData.timeInSeconds = moment().unix();
  //   this.voipService.logVoip(this.voipData).then(dataResp => {
  //     this.voipService.doAnswer();
  //   });
  // }

  // voipDecline() {
  //   this.voipData.msgEvent = 'reject';
  //   this.voipData.timeInSeconds = moment().unix();
  //   this.voipService.logVoip(this.voipData).then(dataResp => {
  //     this.voipService.doDecline();
  //   });
  // }

  // voipCall(voipData) {
  //   this.voipData.msgEvent = 'make';
  //   this.voipData.timeInSeconds = moment().unix();

  //   this.voipService.getVehicleSipNumber(voipData.busRegNo).subscribe(
  //     respSip => {
  //       if(respSip.sipId === '-') {
  //         this._snackBar.open('No SIP number available on this vehicle '+voipData.busRegNo, null, {
  //           duration: 5000,
  //         });
  //         return false;
  //       }
  //       else {
  //         this.voipService.logVoip(this.voipData).then(dataResp => {
  //           this.voipService.doCall("sip:"+respSip.sipId+"@10.12.0.101");
  //         });
  //       }
  //   },
  //   err => {
  //     this._snackBar.open('No SIP number available on this vehicle '+voipData.busRegNo, null, {
  //       duration: 5000,
  //     });
  //   });

  // }

  // closeVoipPanel() {
  //   this.voipDecline();
  //   this.isShowVoidPanel = false;
  // }

  menuSubscription: Subscription;
  watchMenuChange() {
    const me = this;
    this.menuSubscription = this.menuService.getPinned().subscribe(value => {
      me._spinner.show();
      setTimeout(function () {
        if (me?.map) {
          me.map.updateSize();
        }
      }, 150);
      setTimeout(() => me._spinner.hide(), 350);
    });
  }

  ngOnDestroy() {
    clearInterval(this.intervalMapIncident);
    if (this.socket$) {
      this.socket$.unsubscribe();
    }
    if (this.socketOffRoute$) {
      this.socketOffRoute$.unsubscribe();
    }
    if (this.menuSubscription) {
      this.menuSubscription?.unsubscribe?.();
    }
    // if(this.socketPredefined$) {
    //   this.socketPredefined$.unsubscribe();
    // }
  }

  zoomInToBusTimer: any = null;

  zoomInToBus(stopData) {
    this.cancelZoomInToBus();

    this.zoomInToBusTimer = setTimeout(() => {
      this.zoomBusLocation({ value: stopData });
    }, 500);
  }

  cancelZoomInToBus() {
    if (this.zoomInToBusTimer) {
      clearTimeout(this.zoomInToBusTimer);

      this.zoomInToBusTimer = null;
    }
  }

  predefinedMessageClear(event) {
    console.log(event);
  }

  initMapIncident() {
    // var mapData = {
    var urlAction = 'TrafficIncidents';
    // }
    // var isCheck = event.checked;
    // console.log(isCheck);
    // apiDataMallUrl
    this.commonService.commonDataMallAction(urlAction).subscribe(retData => {
      let resData: any = {
        Roadwork: {
          data: [],
          isActive: false,
        },
        Accident: {
          data: [],
          isActive: false,
        },
        RoadBlock: {
          data: [],
          isActive: false,
        },
        VehicleBreakdown: {
          data: [],
          isActive: false,
        },
      };

      let dataValue = retData.data.value;
      dataValue.forEach(element => {
        switch (element.Type) {
          case 'Roadwork':
            resData.Roadwork.data.push(element);
            break;

          case 'Accident':
            resData.Accident.data.push(element);
            break;

          case 'Road Block':
            resData.RoadBlock.data.push(element);
            break;

          case 'Vehicle breakdown':
            resData.VehicleBreakdown.data.push(element);
            break;

          default:
            break;
        }
      });

      // const resData = dataValue.reduce((acc, curr) => {
      //   if(!acc[curr.Type]) acc[curr.Type] = []; //If this type wasn't previously stored
      //   acc[curr.Type].push(curr);
      //   return acc;
      // },{});
      this.mapIncidentData = resData;
      // this.mapOptions.isIncident = true;
    });
  }

  onMapOption(incidentData) {
    if (incidentData.data.length <= 0) {
      return false;
    }

    var incidentType = incidentData.data[0].Type;
    var imgSrc = 'assets/images/icon/roadwork2-32.png';

    if (incidentType === 'Vehicle breakdown') {
      imgSrc = 'assets/images/icon/no-transfer-32.png';
    } else if (incidentType === 'Accident') {
      imgSrc = 'assets/images/icon/warning-32.png';
    } else if (incidentType === 'Road Block') {
      imgSrc = 'assets/images/icon/stop-32.png';
    }

    var layerName = 'VectorLayerMapOptions-' + incidentType;
    var isLayerExist = this.mapService.isLayerExist(this.map, layerName);
    var vectorLayer: VectorLayer<any> | VectorImageLayer<any>;

    // console.log(isLayerExist);
    incidentData.isActive = !incidentData.isActive;

    if (this.provider !== 'GoogleMap') {
      this.mapService.hideGoogleLayer(this.map);
    }

    if (!isLayerExist) {
      vectorLayer = new VectorImageLayer({
        source: new VectorSource({
          features: [],
        }),
        className: layerName,
        properties: {
          name: layerName,
        },
      });

      incidentData.data.forEach(element => {
        var mapData = {
          imgSrc: imgSrc,
          lat: element.Latitude, //1.3177163829578695,
          lon: element.Longitude, // 103.6962011273168,
          name: element.Type,
          message: element.Message,
        };
        this.mapService.addMapMark(vectorLayer, mapData);
      });

      this.map.addLayer(vectorLayer);
    } else {
      // console.log(incidentData.isActive, this.provider, this.currGoogleLayer);
      // vectorLayer = isLayerExist;
      // vectorLayer.setVisible(incidentData.isActive);
      this.map.removeLayer(isLayerExist);
    }
  }

  onCheckData(data) {
    console.log(data);
  }
}
