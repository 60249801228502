import {
  Injectable,
  ViewChild,
  ViewContainerRef,
  ElementRef,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { freeTextMessageData } from '../others/data-types';
import { Subject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  responsibilities: any;
  setPredefinedData: any = [];

  mqttMessageText = new Subject<any>();

  // public messageSampleList = new Subject<any>();

  constructor(private httpClient: HttpClient) {}

  getAllMessage(): Observable<any> {
    return this.mqttMessageText.asObservable();
  }

  addMessagePanelCache(message) {
    var allMessagePanel = JSON.parse(localStorage.getItem('allMessagePanel'));
    // console.log(allMessagePanel);
    if (allMessagePanel) {
      allMessagePanel.push(message);
      localStorage.setItem('allMessagePanel', JSON.stringify(allMessagePanel));
    } else {
      let messageArr = [];
      messageArr.push(message);
      localStorage.setItem('allMessagePanel', JSON.stringify(messageArr));
    }
  }

  getAllMessagePanelCache() {
    var allMessage = JSON.parse(localStorage.getItem('allMessagePanel'));
    // console.log(allMessage);
    if (allMessage) {
      this.setPredefinedData = allMessage;
      return allMessage;
    }
  }

  addMessageCache(message) {
    var allMessage = JSON.parse(localStorage.getItem('allMessage'));
    console.log(allMessage);
    if (allMessage === null) {
      allMessage = [];
      allMessage.push(message);
      localStorage.setItem('allMessage', JSON.stringify(allMessage));
    } else {
      // let messageArr = [];
      // let allMessageArr = JSON.stringify(allMessage);

      // let vehicleMessage = allMessage.find(msg => msg.busRegNo === message.busRegNo);
      // if(vehicleMessage) {
      //   console.log(vehicleMessage);
      //   // vehicleMessage.push(message);
      //   allMessage.push(message);
      //   localStorage.setItem('allMessage', JSON.stringify(allMessage));
      // }
      // else {
      allMessage.push(message);
      localStorage.setItem('allMessage', JSON.stringify(allMessage));
      // }
    }
  }

  getAllMessageCache() {
    var allMessage = JSON.parse(localStorage.getItem('allMessage'));
    return allMessage === null ? [] : allMessage;
  }

  setMessageVehicleCache(message) {
    var messageVehicle = JSON.parse(localStorage.getItem('messageVehicle'));
    // console.log(messageVehicle);
    if (messageVehicle === null) {
      messageVehicle = [];
      messageVehicle.push(message);
      localStorage.setItem('messageVehicle', JSON.stringify(messageVehicle));
    } else {
      // let messageArr = [];
      messageVehicle.push(message);
      localStorage.setItem('messageVehicle', JSON.stringify(messageVehicle));
    }
  }

  getMessageVehicleCache() {
    var messageVehicle = JSON.parse(localStorage.getItem('messageVehicle'));
    return messageVehicle === null ? [] : messageVehicle;
  }

  setMessageMessageList(message) {
    var messsageList = JSON.parse(localStorage.getItem('messsageList'));
    // console.log(messsageList);
    if (messsageList === null) {
      messsageList = [];
      messsageList.push(message);
      localStorage.setItem('messsageList', JSON.stringify(messsageList));
    } else {
      // let messageArr = [];
      messsageList.push(message);
      localStorage.setItem('messsageList', JSON.stringify(messsageList));
    }
  }

  getMessageMessageList() {
    var messsageList = JSON.parse(localStorage.getItem('messsageList'));
    return messsageList === null ? [] : messsageList;

    // var dataMessage = localStorage.getItem('messsageList');
    // var messsageList;
    // if(typeof dataMessage === 'string') {
    //   messsageList = null;
    // }
    // else {
    //   messsageList = JSON.parse(localStorage.getItem('messsageList'));
    // }
    // console.log(typeof messsageList);
    // return messsageList === null ? [] : messsageList;
  }

  sendFreeTextMessage(data: freeTextMessageData) {
    const bodyData = data; //JSON.stringify(data);

    const promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(
          environment.apiServerUrl + 'operatormessage/sendMessage',
          bodyData
        )
        .subscribe(
          data => {
            resolve({ success: 'true' });
          },
          err => {
            resolve({ success: err });
          },
          () => console.log('done set free text message')
        );
    });

    return promise;
  }

  private eventSource = new Subject<any>();

  private event$ = this.eventSource.asObservable();

  public publish(data: any) {
    this.eventSource.next(data);
  }

  public subscribe(observer: any): Subscription {
    return this.event$.subscribe(observer);
  }

  public unsubscribe(subscription: Subscription) {
    subscription.unsubscribe();
  }

  getAllVehicleDevice() {
    let url = environment.apiServerUrl + 'devices/getAllDeviceAndVoipRecords';

    // let body = {
    //   routeId: this.rptForm.value.route,
    //   startDate: moment(this.rptForm.value.startDate).format('YYYY-MM-DD'),
    //   endDate: moment(this.rptForm.value.endDate).format('YYYY-MM-DD')
    // };

    return this.httpClient.post(url, '').pipe(
      map((data: any) => {
        if (data.length == 0) {
          throw new Error('No record found');
        } else {
          return data;
        }
      })
    );
  }

  // voipData = new Subject<any>();
  // getVoipData(): Observable<any> {
  //   return this.voipData.asObservable();
  // }
}
