<div class="pdm-group pdm-{{dataDuplicate.busRegNo}}" cdkDrag
  [ngClass]="{
    'd-none': !data.isVisible
  }"
>
  <div class="wrapper"
  [ngClass] = "{
    blinkRedYellow: isBlinkRedYellow
  }">
    <div class="bd-header">
        <div class="icon-header">
          <i class="fa fa-window-minimize" (click)="showMinimize()"></i>
          <!-- <i class="fa fa-times-circle" (click)="closeMessagePanel()"></i> -->
        </div>
        <div>
          <span class="float-left route">{{dataDuplicate.routeId}}</span>
          <span class="bus-code" (click)="zoomBusLocation()">{{dataDuplicate.busRegNo}}</span>
        </div>
    </div>
    
    <div class="bd-body">
      <div class="body-wrapper">
        <!-- <div class="row">
          <div class="col-3">
            <div class="bus-route">{{dataDuplicate.routeId}}</div>
          </div>
          <div class="col text-center"> -->
            <!-- <div class="icon icon-phone"></div> -->
            <!-- <div class="icon icon-msg" (click)="showMessage()"></div> -->
          <!-- </div>
        </div> -->

        <!-- <div class="row"> -->
          <div class="col" id="ps-message2" *ngIf="isMessageShow">
            <perfect-scrollbar #chatPS id="ps-message" class="ps-message" [config]="config" [scrollIndicators]="true">
              <ng-container *ngFor="let msg of messages">
                <div class="receive-message {{msg.from}}" *ngIf="dataDuplicate.busRegNo === msg.busRegNo">
                {{msg.messageText}}
                <span class="time">{{msg.messageTime}}</span><!-- | date:'hh:mm a' -->
                </div>
             </ng-container>
            </perfect-scrollbar>
          </div>
        <!-- </div> -->

        <div class="send-message-group" >
          <div class="row">
            <div class="col">
              <mat-form-field class="width100">
                <mat-label>Send message</mat-label>
                <textarea [autofocus]="isAutofocus" matInput class="message-text" (focus)="textMessageFocus()" (blur)="textMessageBlur()" [(ngModel)]="dataDuplicate.messageText"
                [ngClass]="{
                  'messageTextHeight': !isMessageShow
                }"></textarea>
              </mat-form-field>
              <div class="message-box" *ngIf="messageTextRes">
                <span 
                [ngClass]="{
                  'error': isError,
                  'valid': !isError && messageTextRes
                }"
                >{{messageTextRes}}</span>
              </div>
            </div>
          </div>
          <div class="action-footer">
            <!-- <div *ngIf="data.latitude" class="icon-location" (click)="zoomBusLocation()"></div> -->
            <!-- <button mat-stroked-button class="btn-clear" (click)="clearMessage()">Clear</button> -->
            <button mat-flat-button class="btn-dark" (click)="sendMessage()">Send</button>
          </div>
          <div class="row text-left">
            <div class="col" *ngIf="data.latitude">
              <div style="font-size: 1.5vh">{{data.latitude}}, {{data.longitude}}</div>
              
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>