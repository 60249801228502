<div id="playback-group" class="map-layout">
  <div #map class="map"></div>
  <ng-container *ngIf="isHasAccess; else noAccess">
    <!-- <div class="switch-group" *ngIf="serviceList.length > 0">
      <app-playback-switch
        [serviceList]="serviceList"
        (changeService)="onChangeService($event)"
        [switchLoading]="playbackSwitchLoading"></app-playback-switch>
    </div> -->
    <div class="playback-group mat-elevation-z4" fxLayout="column">
      <div
        [formGroup]="form"
        fxLayout="row wrap"
        fxLayoutAlign="space-between"
        fxLayoutGap.lt-lg="8px"
        class="search-container"
        *ngIf="!showPlaybackData || (showPlaybackData && !collapsedSearch)">
        <div fxLayout="row wrap" fxLayoutGap="8px 16px grid">
          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field
              appearance="outline"
              subscriptSizing="dynamic"
              fxFlex="0 1 150px">
              <mat-label>Service No.</mat-label>
              <input
                matInput
                #serviceInput
                #serviceAutocompleteTrigger="matAutocompleteTrigger"
                type="text"
                name="service"
                aria-label="Service No."
                placeholder="Service No."
                formControlName="service"
                [matAutocomplete]="auto" />
              <button
                [disableRipple]="true"
                *ngIf="serviceInput.value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearServiceSearch($event)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete
                requireSelection
                autoActiveFirstOption
                autoSelectActiveOption
                #serviceAutoComplete
                #auto="matAutocomplete"
                (optionSelected)="selectRoute($event)">
                <mat-option
                  *ngFor="let route of filteredServiceOptions | async"
                  [value]="route">
                  {{ route }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field
              fxFlex
              appearance="outline"
              subscriptSizing="dynamic"
              fxFlex="0 1 175px">
              <mat-label>Vehicle (optional)</mat-label>
              <input matInput formControlName="vehicle" />
            </mat-form-field>
          </div>

          <div fxLayout="row wrap" fxLayoutGap="0 16px grid">
            <div fxLayout="row" fxLayoutGap="8px" fxFlex="0 1 360px">
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>Start Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="serviceDayStart"
                  [max]="maxStartDate"
                  formControlName="serviceDayStart" />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="serviceDayStart"></mat-datepicker-toggle>
                <mat-datepicker #serviceDayStart></mat-datepicker>
              </mat-form-field>
              <mat-form-field
                appearance="outline"
                subscriptSizing="dynamic"
                class="timepicker">
                <mat-label>Start Time</mat-label>
                <input
                  matInput
                  [ngxTimepicker]="timeFrom"
                  [format]="24"
                  formControlName="timeFrom"
                  required
                  readonly
                  defaultTime="13:00" />
                <ngx-material-timepicker-toggle
                  [for]="timeFrom"
                  matSuffix></ngx-material-timepicker-toggle>
                <ngx-material-timepicker
                  #timeFrom
                  [format]="24"
                  [theme]="timePickerTheme"
                  defaultTime="13:00"></ngx-material-timepicker>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="8px" fxFlex="0 1 360px">
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>End Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="serviceDayEnd"
                  [max]="maxStartDate"
                  formControlName="serviceDayEnd" />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="serviceDayEnd"></mat-datepicker-toggle>
                <mat-datepicker #serviceDayEnd></mat-datepicker>
              </mat-form-field>
              <mat-form-field
                appearance="outline"
                subscriptSizing="dynamic"
                class="timepicker">
                <mat-label>End Time</mat-label>
                <input
                  matInput
                  [ngxTimepicker]="timeTo"
                  [format]="24"
                  formControlName="timeTo"
                  required
                  readonly
                  defaultTime="13:59" />
                <ngx-material-timepicker-toggle
                  [for]="timeTo"
                  matSuffix></ngx-material-timepicker-toggle>
                <ngx-material-timepicker
                  #timeTo
                  [format]="24"
                  [theme]="timePickerTheme"
                  defaultTime="13:59"></ngx-material-timepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <button
            mat-raised-button
            color="primary"
            fxFlex
            [class.spinner]="isLoader"
            [disabled]="isLoader || !form.valid"
            (click)="onSearchPlayback()">
            <span *ngIf="!isLoader">Search</span>
          </button>
          <button
            mat-icon-button
            matTooltip="Close Search"
            *ngIf="showPlaybackData"
            (click)="toggleSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>

      <div
        class="slider-container"
        fxLayoutAlign="flex-start center"
        *ngIf="showPlaybackData"
        [ngClass]="{
          'container-hidden': !collapsedSearch
        }">
        <mat-slider
          class="time-slider"
          [min]="fromTimestamp"
          [max]="toTimestamp">
          <input
            matSliderThumb
            [(ngModel)]="currentTime"
            (input)="onSliderChange($event)"
            (dragStart)="onSliderStart()" />
          <div class="time-marker-container">
            <div
              class="time-marker"
              *ngFor="let indicator of timeIndicators"
              [ngStyle]="{
                left: indicator.position,
                width: indicator.width === 1 ? '1px' : indicator.width + '%'
              }"></div>
          </div>
        </mat-slider>
      </div>

      <div
        fxFlex="100"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="20px 16px grid"
        *ngIf="showPlaybackData"
        [ngClass]="{
          'container-hidden': !collapsedSearch
        }">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="8px"
          fxFlex>
          <div
            (pointerdown)="onUndoKeyDown()"
            (pointerup)="onUndoKeyUp()"
            matRipple
            [matRippleDisabled]="currentTime <= fromTimestamp"
            class="playback-icon2 cursor"
            [ngClass]="{
              'play-disabled': currentTime <= fromTimestamp,
              'play-enabled': currentTime > fromTimestamp
            }"
            matTooltip="Back"
            [matTooltipDisabled]="currentTime <= fromTimestamp"
            fxFlex="0 0 auto">
            <span class="material-icons">fast_rewind</span>
          </div>
          <div
            fxFlex="0 0 auto"
            matRipple
            class="playback-icon2 cursor"
            (click)="isPaused ? onPause() : onPlay()"
            [matTooltip]="isPaused ? 'Play' : 'Pause'">
            <span class="material-icons" *ngIf="!isPaused">pause</span>
            <span class="material-icons" *ngIf="isPaused">play_arrow</span>
          </div>
          <div
            (pointerdown)="onRedoKeyDown()"
            (pointerup)="onRedoKeyUp()"
            matRipple
            [matRippleDisabled]="currentTime >= toTimestamp"
            class="playback-icon2 cursor"
            [ngClass]="{
              'play-disabled': currentTime >= toTimestamp,
              'play-enabled': currentTime < toTimestamp
            }"
            matTooltip="Forward"
            [matTooltipDisabled]="currentTime >= toTimestamp"
            fxFlex="0 0 auto">
            <span class="material-icons">fast_forward</span>
          </div>
          <mat-form-field
            appearance="outline"
            subscriptSizing="dynamic"
            fxFlex="0 1 160px"
            matTooltip="Playback speed">
            <mat-label>Playback Speed</mat-label>
            <mat-select
              [ngModel]="fastForwardValue"
              (selectionChange)="multiplierChange($event)">
              <mat-option
                *ngFor="let multiplier of multiplierList"
                [value]="multiplier"
                >&#215;{{ multiplier }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="ml-24"
          fxLayoutGap="8px"
          class="time-duration">
          <div class="time-start">{{ currentTimeString }}</div>
        </div>

        <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="8px">
          <div class="time-container">
            <mat-checkbox
              [checked]="this.isSatVisible"
              (change)="onSatToggle($event.checked)"
              >Satellite Count</mat-checkbox
            >
          </div>
          <button mat-stroked-button color="primary" (click)="toggleSearch()">
            <span *ngIf="!isLoader">New Search</span>
          </button>
        </div>
      </div>
    </div>

    <app-playback-sidebar
      [playbackData]="playbackDataEVent"
      [vehicleList]="vehicleList"
      [searchData]="searchData"
      (locateEvent)="onLocateEvent($event)"
      *ngIf="showPlaybackData"></app-playback-sidebar>
  </ng-container>
  <ng-template #noAccess>
    <div *ngIf="!isHasAccess" class="height-100vh noAccess">
      <div class="height-50vh" fxLayout="column" fxLayoutAlign="center center">
        <h3>{{ rightsError }}</h3>
      </div>
    </div>
  </ng-template>
</div>
