import { Component, HostListener, OnInit } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CALL_PATH,
  HOME_PATH,
  LOGIN_PATH,
} from './component/shared/others/constant';
import { ServiceWorkerService } from './component/shared/services/service-worker.service';
import { FirebaseService } from './component/shared/services/firebase.service';
import { PushNotificationService } from './component/shared/services/push-notification.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _dialog: MatDialog,
    private swService: ServiceWorkerService,
    private _firebase: FirebaseService,
    private _notification: PushNotificationService
  ) {}
  urlPath;

  ngOnInit() {
    this.swService.init();
    window.addEventListener('storage', event => {
      // console.log('Storage Event', event);
      const { storageArea, key } = event;
      if (storageArea == localStorage && key !== 'ng2Idle.main.expiry') {
        let token = localStorage.getItem('access_token');
        let redirect = new URLSearchParams(window.location.search).get(
          'redirect'
        );
        // console.log('redirect: ', redirect, this._router.url, event, token);

        if (token) {
          if (key === 'access_token') {
            const decodedToken = jwt_decode<any>(token);
            if (decodedToken) {
              const { agency = '' } = decodedToken || {};
              this._firebase.init(agency);
              this._notification.init();
              if (redirect) {
                // console.log('url path', this.urlPath, redirect);
                const isLogin = redirect?.includes('login');
                if (isLogin) {
                  console.log('NAV: REDIRECT', HOME_PATH);
                  this._router.navigate([HOME_PATH]);
                } else {
                  console.log('NAV: REDIRECT', redirect);
                  this._router.navigate([redirect]).catch(e => {
                    console.log('NAV: REDIRECT ERROR', e, HOME_PATH);
                    this._router.navigate([HOME_PATH]);
                  });
                }
              } else {
                if (this._router.url === '/login') {
                  console.log('NAV: REDIRECT', HOME_PATH);
                  this._router.navigate([HOME_PATH]);
                }

                // this._router.navigate([HOME_PATH]); // cause of reload issue
              }
            }
          }
        } else {
          this._dialog.closeAll();
          if (redirect === null) {
            // console.log('router', this._router.url);
            const noRedirect =
              this._router.url === HOME_PATH ||
              this._router.url === LOGIN_PATH ||
              this._router.url === CALL_PATH;
            this.urlPath = this._router.url;
            this._router.navigate([LOGIN_PATH], {
              queryParams: { redirect: noRedirect ? null : this.urlPath },
            });
          }
        }
      }
    });
  }

  // @HostListener("window:beforeunload", ["$event"]) beforeUnloadHandler(event: Event) {
  //   console.log("222");
  //   window.alert(222);
  //   event.returnValue = "You will leave this page" as any;

  // }

  // @HostListener("window:unload", ["$event"]) unloadHandler(event: Event) {
  //   var bodyOptions = {
  //     "sipId": localStorage.getItem('sipNumber')
  //   }
  //   this._voipService.logOffSipNumber(bodyOptions).subscribe(
  //     resp => {
  //     }
  //   );

  // }
}
