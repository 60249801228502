<div class="notif-wrapper">
  <div class="account-header">
    <mat-list role="list">
      <!-- <mat-list-item>SBS3333S</mat-list-item> -->
      <mat-list-item
        *ngFor="let notif of notifList"
        role="listitem"
        matRipple
        (click)="notifEvent(notif)">
        <div class="notif-text">
          <span>{{ notif.busRegNo }}</span>
          <span class="time">{{ notif?.messageTime }}</span>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
