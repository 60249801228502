// import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { BaseComponent } from './component/base/base.component';
import { MainComponent } from './component/main/main.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './component/login/login.component';
import { HomeComponent } from './component/account/home/home.component';
import { AccountHeaderComponent } from './component/account/account-header/account-header.component';
// import { AccountBaseComponent } from './component/account/account-base/account-base.component';
import { MapService } from './component/shared/services/map.service';
import { ServiceWorkerService } from './component/shared/services/service-worker.service';
import { BusSwitchComponent } from './component/account/home/bus-switch/bus-switch.component';
import { BusDetailsComponent } from './component/account/home/bus-details/bus-details.component';
import { StopDetailsComponent } from './component/account/home/stop-details/stop-details.component';
import { LayerControlComponent } from './component/account/home/layer-control/layer-control.component';

import { PerfectScrollbarModule } from 'perfect-scrollbar-angular';
import { PERFECT_SCROLLBAR_CONFIG } from 'perfect-scrollbar-angular';
import { PerfectScrollbarConfigInterface } from 'perfect-scrollbar-angular';
import { RouteDetailsComponent } from './component/account/home/route-details/route-details.component';
import { AuthService } from './component/shared/services/auth.service';
import { AuthGuard } from './component/shared/services/auth-guard.service';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
} from '@angular/common/http';
import { SettingsComponent } from './component/account/settings/settings.component';
import { SettingsLadderComponent } from './component/account/settings/settings-ladder/settings-ladder.component';
import { SettingsMapComponent } from './component/account/settings/settings-map/settings-map.component';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { ErrorInterceptor } from './component/shared/helpers/error.interceptor';
import { AuthInterceptor } from './component/shared/helpers/auth.interceptor';
import { DataManagementComponent } from './component/account/route-management/data-management/data-management.component';
import { PersonnelManagementComponent } from './component/account/personnel-management/personnel-management.component';
import { PmDriverComponent } from './component/account/personnel-management/pm-driver/pm-driver.component';
import { PmControllerComponent } from './component/account/personnel-management/pm-controller/pm-controller.component';
// import { Ng2SmartTableModule } from 'ng2-smart-table';
import { PmService } from './component/account/personnel-management/pm.service';
import { ModalComponent } from './component/shared/services/modal/modal.component';
import { PmDriverRegistrationComponent } from './component/account/personnel-management/pm-driver/pm-driver-registration/pm-driver-registration.component';
import { CommonModule, DatePipe } from '@angular/common';
import { NgbModule, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
// import { SmartTableDatePickerComponent } from './component/shared/services/smart-table-date-picker/smart-table-date-picker.component';
import { JoinDatePickerComponent } from './component/account/personnel-management/pm-driver/join-date-picker/join-date-picker.component';
// import { TemporaryDropdownComponent } from './component/account/personnel-management/pm-driver/temporary-dropdown/temporary-dropdown.component';
import { VehicleManagementComponent } from './component/account/vehicle-management/vehicle-management.component';
import { VmDriverComponent } from './component/account/vehicle-management/vm-driver/vm-driver.component';
import { VmControllerComponent } from './component/account/vehicle-management/vm-controller/vm-controller.component';
import { VmDriverRegistrationComponent } from './component/account/vehicle-management/vm-driver/vm-driver-registration/vm-driver-registration.component';
import { VmService } from './component/account/vehicle-management/vm.service';
import { VmJoinDatePickerComponent } from './component/account/vehicle-management/vm-driver/vm-join-date-picker/vm-join-date-picker.component';
import { VmTemporaryDropdownComponent } from './component/account/vehicle-management/vm-driver/vm-temporary-dropdown/vm-temporary-dropdown.component';
import { PagerComponent } from './component/account/personnel-management/pager/pager.component';

import { TableModule } from 'primeng/components/table/table';
import { DialogModule } from 'primeng/components/dialog/dialog';
import { ConfirmDialogModule } from 'primeng/components/confirmdialog/confirmdialog';
import { DropdownModule } from 'primeng/components/dropdown/dropdown';
import { MenubarModule } from 'primeng/components/menubar/menubar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SliderModule } from 'primeng/components/slider/slider';
import { MultiSelectModule } from 'primeng/components/multiselect/multiselect';
import { PaginatorModule } from 'primeng/components/paginator/paginator';
import { CalendarModule } from 'primeng/components/calendar/calendar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { TripCompletionCalcService } from './component/shared/services/trip-completion-calc.service';
import { EventsComponent } from './component/account/events/events.component';
import { KpiComponent } from './component/account/kpi/kpi.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ReportsComponent } from './component/account/reports/reports.component';
import { StopsComponent } from './component/account/route-management/data-management/stops/stops.component';
import { RoutesComponent } from './component/account/route-management/data-management/routes/routes.component';
import { ServiceComponent } from './component/account/route-management/data-management/service/service.component';
import { AddEditTripComponent } from './component/account/route-management/data-management/routes/add-edit-trip/add-edit-trip.component';
import { DispatcherComponent } from './component/account/dispatcher/dispatcher.component';
import { TrafficEventsComponent } from './component/account/traffic-events/traffic-events.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MenuService } from './component/shared/services/menu.service';
// import { MaterialModule } from './component/shared/others/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PredefinedMessagesComponent } from './component/account/home/mqtt/predefined-messages/predefined-messages.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { StopDetailsAllComponent } from './component/account/home/stop-details-all/stop-details-all.component';
import { StopDetailsPanelComponent } from './component/account/home/stop-details-panel/stop-details-panel.component';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from './cache-route-reuse.strategy';
import { VoipPanelComponent } from './component/account/home/voip-panel/voip-panel.component';
import { AccountMenuComponent } from './component/account/account-menu/account-menu.component';
import { SidebarMenuComponent } from './component/account/sidebar-menu/sidebar-menu.component';
import { ScheduleComponent } from './component/account/schedule/schedule.component';
import { BusEventReportComponent } from './component/account/reports/bus-event-report/bus-event-report.component';
// import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatSpinner } from '@angular/material';
// import { MatSpinner } from '@angular/material/progress-spinner'
import {
  MomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
// import { MatTableExporterModule } from 'mat-table-exporter';
import { PmDriverEditComponent } from './component/account/personnel-management/pm-driver-mat/pm-driver-edit/pm-driver-edit.component';
import { PmDriverMatComponent } from './component/account/personnel-management/pm-driver-mat/pm-driver-mat.component';
import { VehicleDeviceComponent } from './component/account/vehicle-management/vehicle-device/vehicle-device.component';
import { VehicleDeviceEditComponent } from './component/account/vehicle-management/vehicle-device/vehicle-device-edit/vehicle-device-edit.component';
import { UserManagementComponent } from './component/account/user-management/user-management.component';
import { IncidentManagementComponent } from './component/account/incident-management/incident-management.component';
import { DeviceMonitorComponent } from './component/account/vehicle-management/device-monitor/device-monitor.component';
import { MessageComponent } from './component/account/message/message.component';
import { NotificationComponent } from './component/account/notification/notification.component';
import { StopIntervalReportComponent } from './component/account/reports/stop-interval-report/stop-interval-report.component';
import { VehicleListComponent } from './component/account/vehicle-management/vehicle-list/vehicle-list.component';
import { RouteManagementComponent } from './component/account/route-management/route-management.component';
import { SegmentComponent } from './component/account/route-management/segment/segment.component';
import { UserEditComponent } from './component/account/user-management/user-edit/user-edit.component';
import { ServiceDialog } from './component/shared/services/service-modal/service-modal';
import { DragDropDirective } from './component/common/drag-drop/drag-drop-directive.directive';
import { UploadScheduleComponent } from './component/account/schedule-management/upload-schedule/upload-schedule.component';
import { StopManagementComponent } from './component/account/stop-management/stop-management.component';
import { AddStopModalComponent } from './component/account/stop-management/add-stop-modal/add-stop-modal.component';
import { SearchStopComponent } from './component/account/stop-management/search-stop/search-stop.component';
import { ServiceManagementComponent } from './component/account/service-management/service-management.component';
import { CreateServiceComponent } from './component/account/service-management/create-service/create-service.component';
import { MapModeModalComponent } from './component/account/service-management/map-mode-modal/map-mode-modal.component';
import { ScheduleManagementComponent } from './component/account/schedule-management/schedule-management.component';
import { ServiceScheduleComponent } from './component/account/schedule-management/service-schedule/service-schedule.component';
import { InfoModalComponent } from './component/shared/services/info-modal/info-modal.component';
import { ConfirmModalComponent } from './component/shared/services/confirm-modal/confirm-modal.component';
import { CommonService } from './component/shared/services/common.service';
import { AccountManagementComponent } from './component/account/account-management/account-management.component';
import { GroupAccountComponent } from './component/account/account-management/group-account/group-account.component';
import { MasterDataComponent } from './component/account/master-data/master-data.component';
import { OrganizationalDataComponent } from './component/account/master-data/organizational-data/organizational-data.component';
import { MasterDataVehiclesComponent } from './component/account/master-data/vehicle-list/vehicle-list.component';
import { MasterDataDriversComponent } from './component/account/master-data/driver-list/driver-list.component';
import { AlarmManagementComponent } from './component/account/alarm-management/alarm-management.component';
import { AlarmDetailComponent } from './component/account/alarm-management/alarm-detail/alarm-detail.component';
import { CommonAlarmComponent } from './component/common/common-alarm/common-alarm.component';
import { DutyRosterComponent } from './component/account/duty-roster/duty-roster.component';
import { OnlineStatusComponent } from './component/common/online-status/online-status.component';
import { TripCauseCodeAnalysisComponent } from './component/account/reports/trip-cause-code-analysis/trip-cause-code-analysis.component';
import { DispatcherAssignComponent } from './component/account/dispatcher-assign/dispatcher-assign.component';
import { VehicleAssignComponent } from './component/account/dispatcher-assign/vehicle-assign/vehicle-assign.component';
import { TimeTableComponent } from './component/account/time-table/time-table.component';
import { MultiLadderComponent } from './component/account/multi-ladder/multi-ladder.component';
import { SingleLadderComponent } from './component/account/multi-ladder/single-ladder/single-ladder.component';
import { UploadTimeTableComponent } from './component/account/time-table/upload-time-table/upload-time-table.component';
import { TimeBusDetailsComponent } from './component/account/time-table/time-bus-details/time-bus-details.component';
import { PlaybackComponent } from './component/account/playback/playback.component';
import { PlaybackSwitchComponent } from './component/account/playback/playback-switch/playback-switch.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { PlaybackSidebarComponent } from './component/account/playback/playback-sidebar/playback-sidebar.component';
import { AccountSettingComponent } from './component/account/sidebar-menu/account-setting/account-setting.component';
import { VoipAlarmComponent } from './component/account/account-base/voip-alarm/voip-alarm.component';
import { Home2Component } from './component/account/home2/home2.component';
import { RoleManagementComponent } from './component/account/role-management/role-management.component';
import { AddRoleComponent } from './component/account/role-management/add-role/add-role.component';
import { AddAccountComponent } from './component/account/account-management/add-account/add-account.component';
import { ResponsibilityComponent } from './component/account/account-management/responsibility/responsibility.component';
import { ChangePasswordComponent } from './component/account/sidebar-menu/change-password/change-password.component';
// import { SatDatepickerModule } from 'saturn-datepicker';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { DashboardComponent } from './component/account/dashboard/dashboard.component';
import { CandleChartComponent } from './component/account/dashboard/candle-chart/candle-chart.component';

import { NgApexchartsModule } from 'ng-apexcharts';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { AccountBaseComponent } from './component/account/account-base/account-base.component';
import { BrowserModule, Title } from '@angular/platform-browser';
import { MaterialModule } from './component/shared/others/material.module';
import { MatTableExporterModule } from 'mat-table-exporter';
import { SpinnerComponent } from './component/common/spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { BusScheduleDataManagementComponent } from './component/account/bus-schedule-data-management/bus-schedule-data-management.component';
import { DataStatusComponent } from './component/account/bus-schedule-data-management/data-status/data-status.component';
import { VehicleCalendarComponent } from './component/account/bus-schedule-data-management/vehicle-calendar/vehicle-calendar.component';
import { BusScheduleTableComponent } from './component/account/bus-schedule-data-management/bus-schedule-table/bus-schedule-table.component';
import { EditResponsibilityComponent } from './component/account/account-management/edit-responsibility/edit-responsibility.component';
import { DatesetManualUpdateComponent } from './component/account/bus-schedule-data-management/dateset-manual-update/dateset-manual-update.component';
import { TimeoutModalComponent } from './component/shared/services/timeout-modal/timeout-modal.component';
import { PasswordExpirationComponent } from './component/account/password-expiration/password-expiration.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { FirebaseService } from './component/shared/services/firebase.service';
import { PushNotificationService } from './component/shared/services/push-notification.service';
import { CommsComponent } from './component/comms/comms.component';
import { BroadcastComponent } from './component/comms/broadcast/broadcast.component';
import { JitsiService } from './component/shared/services/jitsi.service';
import { PrivateCallComponent } from './component/private-call/private-call.component';
import { NotificationPermissionComponent } from './component/common/notification-permission/notification-permission.component';
import { MockBusComponent } from './component/test-page/mock-bus/mock-bus.component';
import { LocalForageService } from './component/shared/services/local-forage.service';
import { PidsComponent } from './component/test-page/pids/pids.component';
import { EmergencyCallComponent } from './component/account/account-base/emergency-call/emergency-call.component';
import { EmergencyCallWrapperComponent } from './component/account/account-base/emergency-call-wrapper/emergency-call-wrapper.component';

// openapi
import {
  RosterApiModule,
  RosterConfiguration,
  RosterConfigurationParameters,
} from './component/shared/services/openapi/roster';
import { DriverManagementComponent } from './component/account/driver-management/driver-management.component';
import { DriverListComponent } from './component/account/driver-management/driver-list/driver-list.component';
import { DutyRosteringComponent } from './component/account/driver-management/duty-rostering/duty-rostering.component';
import { SharedPipesModule } from './component/shared/pipes/pipes.module';
import { DriverDetailComponent } from './component/account/driver-management/driver-list/driver-detail/driver-detail.component';

export function apiConfigFactory(): RosterConfiguration {
  const params: RosterConfigurationParameters = {
    basePath: environment.apiServerRosterUrl,
  };
  return new RosterConfiguration(params);
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export const APP_MODULE_PRIMENG = [
  TableModule,
  DialogModule,
  ConfirmDialogModule,
  DropdownModule,
  MenubarModule,
  ScrollingModule,
  SliderModule,
  MultiSelectModule,
  PaginatorModule,
  CalendarModule,
  ProgressSpinnerModule,
  AutoCompleteModule,
];
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  minScrollbarLength: 20,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    BaseComponent,
    MainComponent,
    LoginComponent,
    AccountHeaderComponent,
    AccountBaseComponent,
    SettingsComponent,
    SettingsLadderComponent,
    SettingsMapComponent,
    PersonnelManagementComponent,
    PmDriverComponent,
    PmControllerComponent,
    ModalComponent,
    PmDriverRegistrationComponent,
    // SmartTableDatePickerComponent,
    JoinDatePickerComponent,
    // TemporaryDropdownComponent,
    AccountMenuComponent,
    SidebarMenuComponent,
    NotificationComponent,
    //home
    HomeComponent,
    PredefinedMessagesComponent,
    BusSwitchComponent,
    BusDetailsComponent,
    StopDetailsComponent,
    LayerControlComponent,
    RouteDetailsComponent,
    StopDetailsAllComponent,
    VoipPanelComponent,
    PmDriverEditComponent,
    PmDriverMatComponent,
    //home2
    Home2Component,
    //personnel management
    PagerComponent,
    //vehicle management
    VehicleManagementComponent,
    VmDriverComponent,
    VmControllerComponent,
    VmDriverRegistrationComponent,
    VmJoinDatePickerComponent,
    // VmTemporaryDropdownComponent,
    EventsComponent,
    KpiComponent,
    StopsComponent,
    RoutesComponent,
    ServiceComponent,
    AddEditTripComponent,
    //dispatch
    DispatcherComponent,
    DispatcherAssignComponent,
    VehicleAssignComponent,
    VehicleDeviceComponent,
    VehicleDeviceEditComponent,
    VehicleListComponent,
    TrafficEventsComponent,
    StopDetailsPanelComponent,
    ScheduleComponent,
    //user
    UserManagementComponent,
    UserEditComponent,
    IncidentManagementComponent,
    DeviceMonitorComponent,
    MessageComponent,
    //report
    ReportsComponent,
    StopIntervalReportComponent,
    BusEventReportComponent,
    SegmentComponent,
    TripCauseCodeAnalysisComponent,
    //route management
    DataManagementComponent,
    RouteManagementComponent,
    //service
    ServiceDialog,
    DragDropDirective,
    UploadScheduleComponent,
    //stop management
    StopManagementComponent,
    AddStopModalComponent,
    SearchStopComponent,
    //service management
    ServiceManagementComponent,
    CreateServiceComponent,
    MapModeModalComponent,
    //schedule management
    ServiceScheduleComponent,
    ScheduleManagementComponent,
    //utilities
    InfoModalComponent,
    ConfirmModalComponent,
    //account management
    AccountManagementComponent,
    GroupAccountComponent,
    //Master data
    MasterDataComponent,
    OrganizationalDataComponent,
    MasterDataVehiclesComponent,
    MasterDataDriversComponent,
    //alarm management
    AlarmManagementComponent,
    AlarmDetailComponent,
    //time table
    TimeTableComponent,
    UploadTimeTableComponent,
    TimeBusDetailsComponent,
    //multi ladder
    MultiLadderComponent,
    SingleLadderComponent,
    //common
    CommonAlarmComponent,
    OnlineStatusComponent,
    DutyRosterComponent,
    //playback module
    PlaybackComponent,
    PlaybackSwitchComponent,
    PlaybackSidebarComponent,
    //account modele
    AccountSettingComponent,
    AddAccountComponent,
    ResponsibilityComponent,
    ChangePasswordComponent,
    //global module
    VoipAlarmComponent,
    //Role Management
    RoleManagementComponent,
    AddRoleComponent,
    //Dashboard
    DashboardComponent,
    CandleChartComponent,

    SpinnerComponent,
    BusScheduleDataManagementComponent,
    DataStatusComponent,

    VehicleCalendarComponent,
    BusScheduleTableComponent,

    EditResponsibilityComponent,

    DatesetManualUpdateComponent,

    TimeoutModalComponent,

    PasswordExpirationComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CommsComponent,
    BroadcastComponent,
    PrivateCallComponent,
    NotificationPermissionComponent,
    MockBusComponent,
    PidsComponent,
    EmergencyCallComponent,
    EmergencyCallWrapperComponent,

    //Driver Management
    DriverManagementComponent,
    DriverListComponent,
    DutyRosteringComponent,
    DriverDetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PerfectScrollbarModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    // // Ng2SmartTableModule,
    ReactiveFormsModule,
    NgbModule,
    // //prime ng
    BrowserAnimationsModule,
    // APP_MODULE_PRIMENG,
    // //ngx-chart
    NgxChartsModule,
    MaterialModule,
    FlexLayoutModule,
    DragDropModule,
    MomentDateModule,
    MatTableExporterModule,
    NgxMaterialTimepickerModule,
    // //range date
    // // SatDatepickerModule
    MatPasswordStrengthModule,
    NgApexchartsModule,
    NgxSpinnerModule,

    NgIdleKeepaliveModule.forRoot(),
    CommonModule,
    ServiceWorkerModule.register('service-worker-sw.js', {
      enabled: environment.sw, // allow for development to use service workers
      // Register the ServiceWorker as soon as the application is stable
      // or after 5 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:5000',
    }),

    // openapi
    RosterApiModule.forRoot(apiConfigFactory),

    // Pipes
    SharedPipesModule,
  ],
  exports: [PagerComponent, MaterialModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MapService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    AuthService,
    AuthGuard,
    PmService,
    DatePipe,
    NgbModalConfig,
    MenuService,
    CommonService,
    TripCompletionCalcService,
    ServiceWorkerService,
    FirebaseService,
    PushNotificationService,
    //vehicle management
    VmService,
    DispatcherComponent,
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    JitsiService,
    LocalForageService,
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
