<div class="time-table-group">
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.5)"
    size="large"
    color="#fff"
    type="{{ spinnerType }}"
    [fullScreen]="true"
    ><p style="color: white; font-size: 18px; margin-top: 20px">
      Loading...
    </p></ngx-spinner
  >
  <ng-container *ngIf="isHasAccess; else noAccess">
    <div
      class="header-group"
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <div fxLayout="row">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span class="title-header">{{ pageData.displayPageName }}</span>
          <sup
            ><i
              *ngIf="!isNewWindow"
              class="fa fa-window-restore open-window"
              (click)="openWindow()"></i
          ></sup>
        </div>

        <div
          class="mar-left-10"
          [formGroup]="form"
          fxLayout="row"
          fxLayoutAlign="flex-start center">
          <span>
            <mat-form-field
              appearance="outline"
              subscriptSizing="dynamic"
              style="width: 160px"
              floatLabel="always">
              <mat-label>Service No.</mat-label>
              <input
                matInput
                #serviceInput
                #serviceAutocompleteTrigger="matAutocompleteTrigger"
                type="text"
                name="service"
                aria-label="Service No."
                placeholder="Service No."
                formControlName="service"
                [matAutocomplete]="auto" />
              <button
                [disableRipple]="true"
                *ngIf="serviceInput.value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearServiceSearch($event)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete
                requireSelection
                autoActiveFirstOption
                autoSelectActiveOption
                #serviceAutoComplete
                #auto="matAutocomplete"
                (optionSelected)="selectRoute($event)">
                <mat-option
                  *ngFor="let route of filteredOptions | async"
                  [value]="route">
                  {{ route }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <!-- <mat-form-field
              appearance="outline"
              subscriptSizing="dynamic"
              fxFlex="100"
              floatLabel="always">
              <mat-label>Service No.</mat-label>
              <mat-select
                name="status"
                placeholder="Select Route"
                formControlName="service">
                <mat-option *ngFor="let route of routeList" [value]="route">
                  {{ route }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
            <!-- <mat-form-field
              appearance="outline"
              fxFlex="100"
              subscriptSizing="dynamic"
              floatLabel="always">
              <mat-label>Service No.</mat-label>
              <input matInput formControlName="service" />
            </mat-form-field> -->
          </span>
          <!-- <input matInput placeholder="Service No." formControlName="streetNameBusStop"> -->

          <!-- <mat-form-field floatLabel="always"> -->
          <mat-form-field
            appearance="outline"
            subscriptSizing="dynamic"
            floatLabel="always">
            <mat-label>Service Day</mat-label>
            <input
              matInput
              [matDatepicker]="serviceDay"
              formControlName="serviceDay" />
            <mat-datepicker-toggle
              matSuffix
              [for]="serviceDay"></mat-datepicker-toggle>
            <mat-datepicker #serviceDay></mat-datepicker>
            <!-- <mat-error *ngIf="rptForm.get('date').invalid">Invalid start date</mat-error> -->
          </mat-form-field>

          <span class="timepicker">
            <ngx-timepicker-field
              [format]="24"
              formControlName="timeAfter"
              [defaultTime]="'5:00'"
              [clockTheme]="timePickerTheme"></ngx-timepicker-field>
          </span>

          <button
            class="btn-med mar-left-10"
            mat-raised-button
            type="submit"
            [disabled]="running || !form.valid"
            (click)="onSearchSchedule()"
            [ngClass]="{ 'btn-white': !running && form.valid }">
            Search
          </button>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-around center"
        *ngIf="(selectedServiceData | json) !== '{}'">
        <div>
          <mat-slide-toggle
            [checked]="isHeadway"
            (change)="onDeviationChange($event)"
            labelPosition="before">
            <span>OTA</span>
          </mat-slide-toggle>
          <span class="mar-left-5 mar-bot-5">Headway</span>
        </div>
      </div>

      <!-- <mat-form-field appearance="outline">
      <mat-label>Direction</mat-label>
      <mat-select [(ngModel)]="selectedDirection" #directionPath (selectionChange)="onFilterBlock(directionPath)">
        <mat-option *ngFor="let direction of directionListData" [value]="direction">
          {{direction.direction}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
      <button
        mat-raised-button
        type="button"
        (click)="onChangeDirection()"
        *ngIf="directionListData.length > 1">
        Change direction to
        <ng-container *ngIf="selectedDirection === '2'">1</ng-container>
        <ng-container *ngIf="selectedDirection === '1'">2</ng-container>
      </button>
      <button
        mat-raised-button
        type="button"
        (click)="onUploadFile()"
        class="float-right">
        Upload
      </button>
    </div>

    <div fxLayout="row" class="route-group">
      <div class="left-side custom-page-scroll">
        <table border="0">
          <ng-container *ngIf="selectedServiceData.stopList !== undefined">
            <ng-container *ngIf="(selectedServiceData | json) !== '{}'">
              <thead>
                <tr>
                  <th class="td-sched filter-block">
                    <mat-form-field
                      appearance="outline"
                      subscriptSizing="dynamic"
                      style="width: 100%; padding: 0 12px"
                      floatLabel="always">
                      <mat-label>Block Filter</mat-label>
                      <div
                        *ngIf="selectedBlocks?.length > 0"
                        class="selected-container">
                        <div
                          class="selected-text"
                          [matTooltip]="displayBlocks()"
                          matTooltipPosition="above">
                          <ng-container
                            *ngIf="
                              selectedBlocks.length < 10;
                              else moreSelected
                            ">
                            {{ selectedBlocks ? selectedBlocks[0] : '' }}
                            &nbsp;
                            <span
                              *ngIf="selectedBlocks?.length > 1"
                              class="additional-selection">
                              (+{{ selectedBlocks.length - 1 }})
                            </span>
                          </ng-container>
                          <ng-template #moreSelected>
                            <span class="additional-selection">
                              {{ selectedBlocks.length }}
                              selected
                            </span>
                          </ng-template>
                        </div>
                        <button
                          (click)="clearBlocks($event)"
                          mat-icon-button
                          class="filter-clear-button"
                          type="button">
                          <mat-icon inline>close</mat-icon>
                        </button>
                      </div>
                      <input
                        matInput
                        #blockSearch
                        #blockAutocompleteTrigger="matAutocompleteTrigger"
                        name="block"
                        aria-label="Block Number"
                        placeholder="Search Blocks"
                        [formControl]="blockControl"
                        [matAutocomplete]="blockAuto" />
                      <mat-autocomplete
                        autoActiveFirstOption
                        autoSelectActiveOption
                        #blockAuto="matAutocomplete"
                        (optionSelected)="selectBlock($event)">
                        <mat-option
                          *ngFor="let block of filteredBlocks | async"
                          [value]="block">
                          <span [class.optionSelected]="isBlockSelected(block)">
                            {{ block }}
                          </span>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </th>
                  <th
                    class="td-sched hori-stop-header td-div"
                    *ngFor="
                      let headerSched of selectedServiceData.stopList.stops;
                      let first = first;
                      let i = index
                    ">
                    <span
                      class="stop-count"
                      [ngClass]="{
                        'special-stop': headerSched.split(';')[1] === '1'
                      }"
                      >{{ i + 1 }}</span
                    >
                    <div>{{ headerSched.split(';')[0] }}</div>
                    <div
                      class="td-div"
                      fxLayout="row"
                      fxLayoutAlign="space-between center">
                      <div fxFlex="25" class="margin-auto">Plan</div>
                      <div fxFlex="25" class="actual-time-th margin-auto">
                        Actual
                      </div>
                      <div class="vehicle-status-th margin-auto">Vehicle</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let direction of selectedServiceData.timetable">
                  <tr
                    *ngFor="let trip of direction.trips; let i = index"
                    class="highlight">
                    <ng-container *ngIf="trip.isTimeFiltered">
                      <td class="td-sched">
                        <!-- <span class="material-icons pointer" *ngIf="!schedData.isInlineEdit">info</span>
                  <span class="material-icons pointer" matTooltip="Edit" matTooltipPosition="above" *ngIf="!schedData.isInlineEdit">edit</span>
                  <span class="material-icons pointer" matTooltip="Cancel" matTooltipPosition="above" *ngIf="schedData.isInlineEdit">cancel</span>
                  <span class="material-icons pointer" matTooltip="Save" matTooltipPosition="above" *ngIf="schedData.isInlineEdit">save</span> -->
                        <div
                          fxLayout="row"
                          fxLayoutAlign="space-between center">
                          <span
                            ><i>({{ i + 1 }})</i> {{ trip.blockId }}</span
                          >
                          <span
                            class="material-icons pointer"
                            (click)="onDispatchDetails(trip)"
                            >info</span
                          >
                        </div>
                      </td>
                      <td
                        class="td-sched"
                        *ngFor="
                          let stop of trip.stops;
                          let first = first;
                          let i = index
                        ">
                        <!-- <div fxLayout="row" fxLayoutAlign="space-between center" class="td-div">
                    <div fxFlex="25">{{stop.schArrTime === 'Invalid date' ? '' : stop.schArrTime}}</div>
                    <div fxFlex="25" class="actual-time">{{stop.obsArrTime === 'Invalid date' ? '' : stop.obsArrTime}}</div>
                    <div>
                      <div class="vehicle-status pointer mar-left-10 {{stop.statusColor}}" 
                      (click)="onBusDetails(trip, stop)" 
                      matTooltip="Click for more details"
                      *ngIf="stop.busRegNo && !isHeadway"
                      >
                        {{stop.busRegNo}}
                      </div>

                      <div class="vehicle-status pointer mar-left-10 {{stop.headwayStatusColor}}" 
                      (click)="onBusDetails(trip, stop)" 
                      matTooltip="Click for more details"
                      *ngIf="stop.busRegNo && isHeadway"
                      >
                        {{stop.busRegNo}}
                      </div>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="space-between center" class="td-div">
                    <div fxFlex="25">{{stop.planHeadway}}</div>
                    <div fxFlex="25" *ngIf="stop.busRegNo && isHeadway" class="actual-time">{{stop.actualHeadway}}</div>
                    <div *ngIf="stop.busRegNo && isHeadway" class="mar-left-10">{{stop.deviationHeadway}}</div>
                  </div> -->
                        <div fxLayout="row" fxLayoutAlign="space-around center">
                          <div
                            fxFlex="25"
                            fxLayout="column"
                            fxLayoutAlign="space-around center">
                            <div class="planActual 1">
                              {{
                                stop.schArrTime === 'Invalid date' ||
                                stop.schArrTime === null ||
                                stop.schArrTime === ''
                                  ? '-'
                                  : stop.schArrTime
                              }}
                            </div>
                            <div class="planActual 2" *ngIf="isHeadway">
                              {{ stop.planHeadway }}
                            </div>
                          </div>

                          <div
                            fxFlex="25"
                            fxLayout="column"
                            fxLayoutAlign="space-around center">
                            <div class="planActual actual-time 3">
                              {{
                                stop.obsArrTime === 'Invalid date' ||
                                stop.obsArrTime === null ||
                                stop.obsArrTime === ''
                                  ? '-'
                                  : stop.obsArrTime
                              }}
                            </div>
                            <div
                              class="planActual actual-time 4"
                              *ngIf="isHeadway">
                              {{ stop.actualHeadway }}
                            </div>
                          </div>

                          <div
                            fxLayout="column"
                            fxLayoutAlign="space-around center">
                            <div class="planActual">
                              <div
                                class="vehicle-status pointer mar-left-10 5 {{
                                  stop.statusColor
                                }}"
                                (click)="onBusDetails(trip, stop)"
                                matTooltip="Click for more details"
                                *ngIf="stop.busRegNo && !isHeadway">
                                {{ stop.busRegNo }}
                              </div>

                              <div
                                class="vehicle-status pointer mar-left-10 6 {{
                                  stop.headwayStatusColor
                                }}"
                                (click)="onBusDetails(trip, stop)"
                                matTooltip="Click for more details"
                                *ngIf="stop.busRegNo && isHeadway">
                                {{ stop.busRegNo }}
                              </div>

                              <div class="mar-left-10 6" *ngIf="!stop.busRegNo">
                                -
                              </div>
                            </div>
                            <div *ngIf="isHeadway" class="mar-left-10 7">
                              {{ stop.deviationHeadway }}
                            </div>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                  </tr>
                  <!-- <tr><td [attr.colspan]="selectedServiceData.stopList.length">Load More</td></tr> -->
                </ng-container>
              </tbody>
            </ng-container>
          </ng-container>
        </table>

        <!-- WORKING
        <div *ngIf="(selectedServiceData | json) !== '{}' && selectedBlock.length === 0 && countLoadedData <= cloneSelectedServiceData.timetable[0].trips.length " class="text-center load-more">
        <button mat-raised-button color="primary" (click)="onYReachEnd()"
        [disabled]="(countLoadedData >= 
        (selectedDirection === '1' ? 
        cloneSelectedServiceData.timetable[0].trips.length : cloneSelectedServiceData.timetable[0].trips.length)) || 
        isLazyLoading"
        >
          {{lazyLoadingMessage}}
          {{countLoadedData}}/{{selectedDirection === '1' ? 
          cloneSelectedServiceData.timetable[0].trips.length : cloneSelectedServiceData.timetable[0].trips.length}}
        </button>
      </div> -->

        <div
          *ngIf="
            (selectedServiceData | json) !== '{}' &&
            selectedBlocks.length === 0 &&
            countLoadedData <=
              cloneSelectedServiceData.timetable[0].trips.length
          "
          class="text-center load-more">
          <button
            mat-raised-button
            color="primary"
            (click)="onYReachEnd()"
            [disabled]="
              countLoadedData >=
                cloneSelectedServiceData.timetable[0].trips.length ||
              isLazyLoading
            ">
            {{ lazyLoadingMessage }}
            {{ countLoadedData }}/{{
              cloneSelectedServiceData.timetable[0].trips.length
            }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #noAccess>
    <div class="height-50vh" fxLayout="column" fxLayoutAlign="center center">
      <h3>{{ rightsError }}</h3>
    </div>
  </ng-template>
</div>

<div id="videos" class="d-none">
  <video
    class="rounded centered"
    id="myvideo"
    width="320"
    height="240"
    autoplay
    playsinline
    muted="muted"></video>
  <video
    class="rounded centered hide"
    id="remotevideo"
    width="320"
    height="240"
    autoplay
    playsinline></video>
</div>
