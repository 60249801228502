<!-- <div class="mqtt-group">
  <div class="mqtt-wrapper">
    <ng-container *ngFor="let message of homeService.setPredefinedData; let i = index">
      <app-predefined-messages [data]="message" (closeMessage)="closeMessagePanel($event)" (predefinedMessageZoom)="predefinedMessageZoom($event)" (predefinedMessageClear)=predefinedMessageClear($event)></app-predefined-messages>
    </ng-container>
  </div>
</div> -->
<ng-container *ngIf="isHasAccess; else noAccess">
  <div class="bus-explorer" *ngIf="isBusExplorer">
    <div class="explorer-header">
      <div>
        Bus Explorer
        <i class="material-icons cancel" (click)="closeBusExplorer()"
          >highlight_off</i
        >
      </div>
    </div>
    <mat-list class="route-list" role="list">
      <mat-list-item role="listitem" *ngFor="let data of busExplorerData">
        {{ data.name }}
      </mat-list-item>
    </mat-list>
  </div>

  <ngx-spinner
    bdColor="rgba(27, 30, 39, 1)"
    size="large"
    color="#fff"
    type="ball-clip-rotate"
    [fullScreen]="true"
    [disableAnimation]="true"></ngx-spinner>
  <div id="map" class="map"></div>

  <!-- temporary removed -->
  <!-- <div class="map-options-group">
    <div class="incident-group">
      <div class="text-right" fxLayout="row" fxLayoutAlign="space-between center" >
        <div *ngIf="mapOptions.isIncident" [@fadeInOut] class="map-options">
          <button mat-mini-fab matBadge="{{mapIncidentData.RoadBlock.data.length}}" matBadgeColor="warn" matTooltip="Road block"
          (click)="onMapOption(mapIncidentData.RoadBlock)" [ngClass]="{'roadblock-active': mapIncidentData.RoadBlock.isActive}">
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
          <button mat-mini-fab matBadge="{{mapIncidentData.Accident.data.length}}" matBadgeColor="warn" matTooltip="Accident"
          (click)="onMapOption(mapIncidentData.Accident)" [ngClass]="{'accident-active': mapIncidentData.Accident.isActive}">
            <mat-icon>warning</mat-icon>
          </button>
          <button mat-mini-fab matBadge="{{mapIncidentData.Roadwork.data.length}}" matBadgeColor="warn" matTooltip="Road works"
          (click)="onMapOption(mapIncidentData.Roadwork)" [ngClass]="{'roadwork-active': mapIncidentData.Roadwork.isActive}">
            <div class="icon-roadwork"></div>
          </button>
          <button mat-mini-fab matBadge="{{mapIncidentData.VehicleBreakdown.data.length}}" matBadgeColor="warn" matTooltip="Breakdown"
          (click)="onMapOption(mapIncidentData.VehicleBreakdown)" [ngClass]="{'breakdown-active': mapIncidentData.VehicleBreakdown.isActive}">
            <mat-icon>no_transfer</mat-icon>
          </button>
        </div>
        <button mat-icon-button (click)="mapOptions.isIncident = !mapOptions.isIncident">
          <mat-icon *ngIf="!mapOptions.isIncident" matTooltip="Incidents">keyboard_arrow_left</mat-icon>
          <mat-icon *ngIf="mapOptions.isIncident">keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </div>
    <div class="ladder-group-option">
      <button mat-icon-button (click)="mapOptions.isLadder = !mapOptions.isLadder">
        <mat-icon *ngIf="!mapOptions.isLadder">keyboard_arrow_left</mat-icon>
        <mat-icon *ngIf="mapOptions.isLadder">keyboard_arrow_right</mat-icon>
      </button>
    </div>
  </div> -->
  <!-- end temporary removed -->

  <app-panel-layer-control
    [selectedBasemap]="selectedBasemap"
    (onClickMapTypeEvent)="applyMapStyle($event)"
    [listOverlayLayers]="listOverlayLayers"
    (onClickOverlayEvent)="toggleOverlayLayer($event)">
  </app-panel-layer-control>

  <!-- <div class="map-layer-group">
    <div fxLayout="row" fxLayoutAlign="space-between center" >
      <div *ngIf="mapOptions.isMapLayer" [@fadeInOut] class="map-layers">
        <mat-radio-group aria-label="Select an option" fxLayout="column"> 
          <mat-radio-button [checked]="provider === 'GoogleMap'" value="GoogleMap" (change)="onChangeProvider('GoogleMap')">Google Map</mat-radio-button>
          <div class="map-types" *ngIf="provider === 'GoogleMap'">
            <mat-slide-toggle [checked]="true" (change)="trafficToggle($event)">Traffic Layer</mat-slide-toggle>
            <mat-radio-group class="mar-top-10" aria-label="Select an option" fxLayout="column"> 
              <mat-radio-button [checked]="currGoogleLayer === 'roadmap'" value="roadmap" (change)="onChangeMapType('roadmap')">Road</mat-radio-button>
              <mat-radio-button [checked]="currGoogleLayer === 'terrain'" value="terrain" (change)="onChangeMapType('terrain')">Terrain</mat-radio-button>
              <mat-radio-button [checked]="currGoogleLayer === 'satellite'" value="satellite" (change)="onChangeMapType('satellite')">Satelite</mat-radio-button>
              <mat-radio-button [checked]="currGoogleLayer === 'hybrid'" value="hybrid" (change)="onChangeMapType('hybrid')">Hybrid</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-radio-button [checked]="provider === 'OpenStreetMap'" value="OpenStreetMap" (change)="onChangeProvider('OpenStreetMap')">Open Street Map</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="ladder-group-option">
        <button mat-icon-button (click)="mapOptions.isMapLayer = !mapOptions.isMapLayer">
          <mat-icon *ngIf="!mapOptions.isMapLayer">layers</mat-icon>
          <mat-icon *ngIf="mapOptions.isMapLayer">keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </div>
  </div> -->

  <app-stop-details-panel></app-stop-details-panel>

  <!-- <button id="event-call-listener" class="d-none" (click)="initIncomingData()"></button> -->

  <div [@fadeInOut] *ngIf="mapOptions.isLadder">
    <div
      class="ladder-group {{ orientation }}"
      *ngIf="isSwitch"
      [ngClass]="{ 'loop-laddder': !isBirectional }">
      <div class="ladder {{ orientation }} mat-elevation-z4">
        <div class="container width100">
          <div class="row container-row" *ngIf="ladders.isVisible === 'false'">
            <!-- <div *ngIf="!environment.isRouteNameUse">
              <div class="bus-number" (click)="onRouteDetails(ladders)">
                {{ ladders.route }}
              </div>
            </div>
            <div *ngIf="environment.isRouteNameUse">
              <div
                class="bus-number font-size-inherit"
                (click)="onRouteDetails(ladders)">
                {{ routeName }}
              </div>
            </div> -->
            <div class="col route-buses">
              <!-- <div class="row row-first"></div> -->
              <div class="row row-between">
                <div
                  class="icon-bus-a bus-a-desc"
                  (click)="openAllRoutes(1)"
                  (dblclick)="closeAllRoutes(1)">
                  {{ ladders.from.location }}
                  <i
                    class="fa fa-arrow-right gray"
                    *ngIf="
                      orientation === 'bottom' ||
                      orientation === 'middle' ||
                      orientation === 'top'
                    "></i>

                  <i
                    class="fa fa-arrow-down gray"
                    *ngIf="orientation === 'right'"></i>
                </div>
                <div
                  class="col route bus-a-route"
                  [ngClass]="{ 'route-loop': !isBirectional }"
                  *ngIf="
                    orientation === 'bottom' ||
                    orientation === 'middle' ||
                    orientation === 'top'
                  ">
                  <!-- <div class="buses-a" *ngIf="(busMqtt | json) != '{}' || busMqtt === 'undefined' || busMqtt === 'null' || busMqtt === ''">
                    <ng-container *ngIf="busMqtt?.length > 0">
                      <div class="bus" *ngFor="let bus of busMqtt">
                        <ng-container *ngIf="bus.value === '1'">
                          <i [ngClass]="{
                            'statusGreen': bus.status === '0',
                            'statusYellow': bus.status === '-1',
                            'statusRed': bus.status === '1',
                            'statusGray': bus.status === '2'
                          }" 
                          class="fa fa-caret-right bus-bunched1" 
                          [style.left.%]="bus.ratio" 
                          (click)="onBusAction($event, bus, 1, orientation, bus.value)" *ngIf="orientation === 'bottom' || orientation === 'middle' || orientation === 'top'">
                            <div class="ladder-bus-name" [ngClass]="{'bus-selected':bus.isSelected}"
                            (mouseenter) ="zoomInToBus(bus)"  (mouseleave) ="cancelZoomInToBus()">{{bus.busCode}}</div>
                          </i> -->

                  <!-- <i [ngClass]="{
                            'statusGreen': bus.status === '0',
                            'statusYellow': bus.status === '-1',
                            'statusRed': bus.status === '1',
                            'statusGray': bus.status === '2'
                          }" 
                          class="fa fa-caret-right" 
                          [style.top.%]="bus.ratio" 
                          (click)="onBusAction($event, bus, 1, orientation, bus.value)" *ngIf="orientation === 'right'">
                          </i> -->
                  <!-- </ng-container>
                      </div> -->

                  <!-- <div class="bus bus-bunching" style="
                          height: 2vh;
                          background-color: #ffffff54;
                          position: absolute;
                          top: 4.3vh;
                          border-radius: 1vh;"
                      [style.left.%]="bbPercentage - .5"
                      [style.width.%]="bbWidth + 1.5"
                      ></div> -->

                  <!-- </ng-container>
                  </div> -->
                  <!-- <ng-container *ngIf="(busMqtt | json) === '{}'">
                      <div class="bus-not-found">
                        No vehicle on road
                      </div>
                  </ng-container> -->

                  <ng-container *ngFor="let direction of ladders.direction">
                    <ng-container *ngIf="direction.value === '1'">
                      <div
                        class="ladderStopToolTip"
                        *ngFor="
                          let ladder of direction.stops;
                          let isLast = last;
                          let isEven = even;
                          let index = index
                        "
                        [ngStyle]="{
                          width: !isLast ? direction.routeWidth + '%' : 0
                        }">
                        <!-- start vehicle by stop -->
                        <div class="buses-a" *ngIf="busMqtt?.length > 0">
                          <div class="bus" *ngFor="let bus of busMqtt">
                            <ng-container
                              *ngIf="
                                bus.value === '1' &&
                                bus.prevStopId === ladder.stopCode
                              ">
                              <!-- <div (click)="onCheckData(direction.stops[index+1])">checkData</div> -->
                              <!-- not last stop -->
                              <div
                                class="stop-marker"
                                [style.left.%]="
                                  bus.prevStopId === bus.nextStopId
                                    ? 0
                                    : bus.drivenPercentage
                                "
                                *ngIf="
                                  (direction.stops[index + 1] !== undefined &&
                                    bus.nextStopId ===
                                      direction.stops[index + 1].stopCode) ||
                                  (bus.prevStopId === bus.nextStopId &&
                                    ladder.stopSequence === '1')
                                ">
                                <i
                                  [ngClass]="{
                                    statusGreen: bus.status === '0',
                                    statusYellow: bus.status === '-1',
                                    statusRed: bus.status === '1',
                                    statusGray: bus.status === '2',
                                    statusOrange: bus.status === '3'
                                  }"
                                  class="fa fa-caret-right bus-bunched1 bus-data"
                                  (click)="
                                    onBusAction(
                                      $event,
                                      bus,
                                      1,
                                      orientation,
                                      bus.value
                                    )
                                  ">
                                  <!-- 'bus-selected': bus.isSelected, -->
                                  <div
                                    class="ladder-bus-name"
                                    [ngClass]="{
                                      'bus-selected': false,
                                      bgGreen: bus.status === '0',
                                      'bgYellow textBlack': bus.status === '-1',
                                      bgRed: bus.status === '1',
                                      bgGray: bus.status === '2',
                                      bgOrange: bus.status === '3'
                                    }"
                                    (mouseenter)="zoomInToBus(bus)"
                                    (mouseleave)="cancelZoomInToBus()">
                                    {{ bus.busCode }}
                                  </div>
                                </i>
                              </div>

                              <!-- last stop -->
                              <div
                                class="stop-marker"
                                [style.left.%]="bus.drivenPercentage"
                                *ngIf="
                                  direction.stops[index + 1] === undefined &&
                                  bus.nextStopId === ''
                                ">
                                <!-- bus.secondnextstopId !== direction.stops[index+1].stopCode &&
                              bus.nextStopId === '' -->
                                <i
                                  [ngClass]="{
                                    statusGreen: bus.status === '0',
                                    statusYellow: bus.status === '-1',
                                    statusRed: bus.status === '1',
                                    statusGray: bus.status === '2',
                                    statusOrange: bus.status === '3'
                                  }"
                                  class="fa fa-caret-right bus-bunched1 bus-data"
                                  (click)="
                                    onBusAction(
                                      $event,
                                      bus,
                                      1,
                                      orientation,
                                      bus.value
                                    )
                                  ">
                                  <div
                                    class="ladder-bus-name"
                                    [ngClass]="{
                                      'bus-selected': false,
                                      bgGreen: bus.status === '0',
                                      'bgYellow textBlack': bus.status === '-1',
                                      bgRed: bus.status === '1',
                                      bgGray: bus.status === '2',
                                      bgOrange: bus.status === '3'
                                    }"
                                    (mouseenter)="zoomInToBus(bus)"
                                    (mouseleave)="cancelZoomInToBus()">
                                    {{ bus.busCode }}
                                  </div>
                                </i>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <!-- end vehicle by stop -->

                        <i
                          [ngClass]="{
                            isEven: isEven,
                            'featured-stop': ladder.isMajorStop !== 'true'
                          }"
                          class="fa fa-circle gray"
                          (click)="
                            onStopDetails(ladder, orientation, direction.value)
                          "
                          (mouseenter)="highlightBusStopOnLadderAndMap(ladder)"
                          (mouseleave)="
                            unhighlightBusStopOnLadderAndMap(ladder)
                          "></i>
                        <div class="route-line" *ngIf="!isLast"></div>
                        <span
                          class="ladderStopToolTipText direction1 mat-elevation-z2"
                          [ngClass]="{
                            ladderStopToolTipVisible: ladder.highlight === true
                          }">
                          {{ ladder.stopName }}
                        </span>
                      </div>

                      <div
                        class="bus-not-found"
                        *ngIf="(busMqtt | json) === '{}'">
                        No vehicle on road
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
                <!-- <div class="col-9 route bus-a-route" *ngIf="orientation === 'right'">
                  <div class="ladderStopToolTip" *ngFor="let ladder of ladders.routesA; 
                  let isLast = last; 
                  let isEven = even;" 
                  [ngStyle]="{'height': !isLast ? ladders.routeWidthA + '%' : 0}"
                  (mouseenter) ="highlightBusStopOnLadderAndMap(ladder)"  (mouseleave) ="unhighlightBusStopOnLadderAndMap(ladder)">
                    <i [ngClass]="{
                      'isEven': isEven,
                      'featured-stop': tr
                    }" class="fa fa-circle gray" (click)="onStopDetails(ladder, orientation, direction.value)" ></i>
                    <div class="route-line" [style.height.%]="ladders.routeWidthA" *ngIf="!isLast"></div>
                    <span class="ladderStopToolTipText" [ngClass]="{'ladderStopToolTipVisible': ladder.highlight === true}">{{ladder.stopName}}</span>
                  </div>
                </div> -->
                <div class="">
                  <div class="bus-b-desc visibility-hidden" *ngIf="ladders.to">
                    <i
                      class="fa fa-arrow-left gray padding-b-icon"
                      *ngIf="
                        orientation === 'bottom' ||
                        orientation === 'middle' ||
                        orientation === 'top'
                      "></i>
                    <i
                      class="fa fa-arrow-up gray"
                      *ngIf="orientation === 'right'"></i>
                    {{ ladders.to.location }}
                  </div>
                </div>
              </div>

              <div class="row row-between">
                <div class="icon-bus-a visibility-hidden bus-a-desc">
                  {{ ladders.from.location }}
                  <i
                    class="fa fa-arrow-right gray"
                    *ngIf="
                      orientation === 'bottom' ||
                      orientation === 'middle' ||
                      orientation === 'top'
                    "></i>
                </div>
                <div
                  class="col route bus-b-route"
                  *ngIf="
                    orientation === 'bottom' ||
                    orientation === 'middle' ||
                    orientation === 'top'
                  ">
                  <!-- <div class="buses-b">
                    <ng-container *ngIf="busMqtt?.length > 0">
                      <div class="bus" *ngFor="let bus of busMqtt">
                        <ng-container *ngIf="bus.value === '2'">
                          <i [ngClass]="{
                            'statusGreen': bus.status === '0',
                            'statusYellow': bus.status === '-1',
                            'statusRed': bus.status === '1',
                            'statusGray': bus.status === '2'
                          }" 
                          class="fa fa-caret-left" 
                          [style.left.%]="bus.ratio" 
                          (click)="onBusAction($event, bus, 1, orientation, bus.value)" *ngIf="orientation === 'bottom' || orientation === 'middle' || orientation === 'top'">
                            <div class="ladder-bus-name" [ngClass]="{'bus-selected':bus.isSelected}"
                            (mouseenter) ="zoomInToBus(bus)"  (mouseleave) ="cancelZoomInToBus()">{{bus.busCode}}</div>
                          </i>
                          <i [ngClass]="{
                            'statusGreen': bus.status === '0',
                            'statusYellow': bus.status === '-1',
                            'statusRed': bus.status === '1',
                            'statusGray': bus.status === '2'
                          }" 
                          class="fa fa-caret-left" 
                          [style.top.%]="bus.ratio" 
                          (click)="onBusAction($event, bus, 1, orientation, bus.value)" *ngIf="orientation === 'right'">
                          </i>
                        </ng-container>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="(busMqtt | json) === '{}'">
                        <div class="bus-not-found">
                          No vehicle on road
                        </div>
                    </ng-container>
                    </div> -->

                  <ng-container *ngFor="let direction of ladders.direction">
                    <ng-container *ngIf="direction.value === '2'">
                      <div
                        class="ladderStopToolTip"
                        *ngFor="
                          let ladder of direction.stops.slice().reverse();
                          let isLast = last;
                          let isEven = even
                        "
                        [ngStyle]="{
                          width: !isLast ? direction.routeWidth + '%' : 0
                        }"
                        (mouseenter)="highlightBusStopOnLadderAndMap(ladder)"
                        (mouseleave)="unhighlightBusStopOnLadderAndMap(ladder)">
                        <!-- start vehicle by stop -->
                        <div class="buses-b" *ngIf="busMqtt?.length > 0">
                          <div class="bus" *ngFor="let bus of busMqtt">
                            <ng-container
                              *ngIf="
                                bus.value === '2' &&
                                bus.prevStopId === ladder.stopCode
                              ">
                              <div
                                class="stop-marker"
                                [style.right.%]="
                                  bus.prevStopId === bus.nextStopId
                                    ? 0
                                    : bus.drivenPercentage
                                "
                                *ngIf="
                                  (direction.stops[index + 1] !== undefined &&
                                    bus.nextStopId ===
                                      direction.stops[index + 1].stopCode) ||
                                  (bus.prevStopId === bus.nextStopId &&
                                    ladder.stopSequence === '1')
                                ">
                                <!-- <i class="fa fa-caret-right statusRed bus-data"></i> -->
                                <i
                                  [ngClass]="{
                                    statusGreen: bus.status === '0',
                                    statusYellow: bus.status === '-1',
                                    statusRed: bus.status === '1',
                                    statusGray: bus.status === '2',
                                    statusOrange: bus.status === '3'
                                  }"
                                  class="fa fa-caret-left bus-bunched1 bus-data"
                                  (click)="
                                    onBusAction(
                                      $event,
                                      bus,
                                      1,
                                      orientation,
                                      bus.value
                                    )
                                  ">
                                  <div
                                    class="ladder-bus-name"
                                    [ngClass]="{
                                      'bus-selected': false,
                                      bgGreen: bus.status === '0',
                                      'bgYellow textBlack': bus.status === '-1',
                                      bgRed: bus.status === '1',
                                      bgGray: bus.status === '2',
                                      bgOrange: bus.status === '3'
                                    }"
                                    (mouseenter)="zoomInToBus(bus)"
                                    (mouseleave)="cancelZoomInToBus()">
                                    {{ bus.busCode }}
                                  </div>
                                </i>
                              </div>

                              <!-- last stop -->
                              <div
                                class="stop-marker"
                                [style.right.%]="bus.drivenPercentage"
                                *ngIf="
                                  direction.stops[index + 1] === undefined &&
                                  bus.nextStopId === ''
                                ">
                                <!-- <i class="fa fa-caret-right statusRed bus-data"></i> -->
                                <i
                                  [ngClass]="{
                                    statusGreen: bus.status === '0',
                                    statusYellow: bus.status === '-1',
                                    statusRed: bus.status === '1',
                                    statusGray: bus.status === '2',
                                    statusOrange: bus.status === '3'
                                  }"
                                  class="fa fa-caret-left bus-bunched1 bus-data"
                                  (click)="
                                    onBusAction(
                                      $event,
                                      bus,
                                      1,
                                      orientation,
                                      bus.value
                                    )
                                  ">
                                  <div
                                    class="ladder-bus-name"
                                    [ngClass]="{
                                      'bus-selected': false,
                                      bgGreen: bus.status === '0',
                                      'bgYellow textBlack': bus.status === '-1',
                                      bgRed: bus.status === '1',
                                      bgGray: bus.status === '2',
                                      bgOrange: bus.status === '3'
                                    }"
                                    (mouseenter)="zoomInToBus(bus)"
                                    (mouseleave)="cancelZoomInToBus()">
                                    {{ bus.busCode }}
                                  </div>
                                </i>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <!-- end vehicle by stop -->

                        <i
                          [ngClass]="{
                            isEven: isEven,
                            'featured-stop': ladder.isMajorStop !== 'true'
                          }"
                          class="fa fa-circle gray"
                          (click)="
                            onStopDetails(ladder, orientation, direction.value)
                          "></i>
                        <div class="route-line" *ngIf="!isLast"></div>
                        <span
                          class="ladderStopToolTipText direction2 mat-elevation-z2"
                          [ngClass]="{
                            ladderStopToolTipVisible: ladder.highlight === true
                          }">
                          {{ ladder.stopName }}
                        </span>
                      </div>

                      <div
                        class="bus-not-found"
                        *ngIf="(busMqtt | json) === '{}'">
                        No vehicle on road
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
                <!-- <div class="col-9 route bus-b-route" *ngIf="orientation === 'right'">
                  <div class="ladderStopToolTip" *ngFor="let ladder of ladders.routesB; 
                  let isLast = last; 
                  let isEven = even;" 
                  [ngStyle]="{'height': !isLast ? ladders.routeWidthB + '%' : 0}"
                  (mouseenter) ="highlightBusStopOnLadderAndMap(ladder)"  (mouseleave) ="unhighlightBusStopOnLadderAndMap(ladder)">
                    <i [ngClass]="{
                      'isEven': isEven,
                      'featured-stop': ladder.isFeatured === 'true'
                    }" class="fa fa-circle gray" (click)="onStopDetails(ladder, orientation, direction.value)" ></i>
                    <div class="route-line" [style.height.%]="ladders.routeWidthB" *ngIf="!isLast"></div>
                    <span class="ladderStopToolTipText" [ngClass]="{'ladderStopToolTipVisible': ladder.highlight === true}">{{ladder.stopName}}</span>
                  </div>
                </div> -->
                <div class="col icon-bus-b">
                  <div
                    class="bus-b-desc"
                    *ngIf="ladders.to"
                    (click)="openAllRoutes(2)"
                    (dblclick)="closeAllRoutes(2)">
                    <i
                      class="fa fa-arrow-left gray padding-b-icon"
                      *ngIf="
                        orientation === 'bottom' ||
                        orientation === 'middle' ||
                        orientation === 'top'
                      "></i>
                    <i
                      class="fa fa-arrow-up gray"
                      *ngIf="orientation === 'right'"></i>
                    {{ ladders.to.location }}
                  </div>
                </div>
              </div>

              <!-- <div class="row row-last">
                <div class="col-1 icon-bus-b"></div>
                <div class="col-1 icon-bus-b"></div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="{ 'div-disabled': isSwitchDisabled }">
    <app-bus-switch
      [@fadeInOut]
      *ngIf="(busSwitchListData | json) !== '{}'"
      [(data)]="busSwitchListData"
      [disabled]="isSwitchDisabled"
      (busData)="busSwitch($event)"></app-bus-switch>
  </div>
  <div
    class="bus-details {{ orientation }}"
    fxLayout="row"
    fxLayoutAlign="flex-start center">
    <!-- <div #busDetailsWrapper></div> -->
    <div *ngFor="let busDetails of busDetailsData" @quickFade>
      <app-bus-details
        [data]="busDetails"
        (closeDetails)="removeBusComponent($event)"></app-bus-details>
    </div>
  </div>

  <div id="videos" class="d-none">
    <video
      class="rounded centered"
      id="myvideo"
      width="320"
      height="240"
      autoplay
      playsinline
      muted="muted"></video>
    <video
      class="rounded centered hide"
      id="remotevideo"
      width="320"
      height="240"
      autoplay
      playsinline></video>
  </div>
</ng-container>

<ng-template #noAccess>
  <div class="height-50vh" fxLayout="column" fxLayoutAlign="center center">
    <h3>{{ rightsError }}</h3>
  </div>
</ng-template>

<!-- <div class="voip-panel-group" cdkDrag
  [ngClass]="{
    'd-none': !isShowVoidPanel
  }">
  <div class="wrapper">
    <div class="bd-header">
      <span class="voip-bus-code"></span>{{voipData === undefined || voipData.busRegNo === undefined ? '' : ' '+voipData.busRegNo}}
      <i class="fa fa-times-circle close-voip" (click)="closeVoipPanel()"></i>
    </div>
    <div class="body">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div class="outgoing-call">
          <div class="icon icon-hangup" (click)="voipHangUp()"><i class="fa fa-phone"></i></div>
        </div>
        <div class="incoming-call d-none" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="icon icon-hangup" (click)="voipDecline()"><i class="fa fa-phone"></i></div>
          <div class="icon icon-answer bounce" (click)="voipAnswer()"><i class="fa fa-phone"></i></div>
        </div>
        <div class="idle-call d-none1">
          <div class="icon icon-answer" (click)="voipCall(voipData)"><i class="fa fa-phone"></i></div>
        </div>
      </div>
      <div class="call-status">Connecting...</div>
    </div>
  </div>
</div> -->
